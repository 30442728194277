import { useEffect, useState } from 'react'

/**
 * Use state synced with LocalStorage
 * Cases:
 *  - undefined: not inited
 *  - null: parse failed
 *  - other values is truly possible
 * @param key
 * @returns [ value,setValue ] tuple same as useState
 */
export const useSyncedState = (key) => {
  const [value, _setValue] = useState(undefined) // not inited

  // Read initial from localStorage
  const getInitialValue = () => {
    try {
      let _result = JSON.parse(localStorage.getItem(key))
      _setValue(_result)
    } catch (e) {
      _setValue(null) // null -> failed
      console.error(e)
    }
  }

  // Init
  useEffect(getInitialValue, [key])

  // Handle setValue
  const setValue = (value) => {
    localStorage.setItem(key, value)
    _setValue(value)
  }

  return [value, setValue]
}
