import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';
// https://www.npmjs.com/package/react-ga

export function GoogleAnalytics({ debug }) {
  const gaCode = process?.env.REACT_APP_GA_CODE;
  const { pathname, search } = useLocation();
  const [gaInited, setGaInited] = useState(false);

  useEffect(() => {
    if (!gaCode) return;

    ReactGA.initialize(gaCode, { debug });
    setGaInited(true);
    console.log(`🟡 GA inited...`, gaCode);

    ReactGA.pageview(window.location.pathname + window.location.search);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaCode]);

  useEffect(() => {
    if (!gaInited) return;
    const page = pathname + search;

    console.log(`🟡 GA send pageview`, page);

    ReactGA.pageview('set', 'page', page);
    ReactGA.pageview('send', 'pageview');

  }, [gaInited, pathname, search]);

  return null;
}

GoogleAnalytics.defaultProps = {
  debug: false,
};

export default ReactGA;
