import { TOGGLE_QUALITY_FEATURE, SET_DEFAULT_VALUE } from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

export const initialState = {
    id: 'qualityfeature',
    value: 1,
    title: 'Originality Report',
    tooltip: 'Your paper will be carefully checked for uniqueness. We will examine multiple online databases, including Turnitin and provide originality report along with your completed assignment.'
}

export const QUALITY = {
    DISABLE: 1,
    ENABLE: 2
}

export default function qualityFeatureReducer(state = initialState, { type, payload }) {

    switch (type) {
        case TOGGLE_QUALITY_FEATURE:
            return {
                ...state,
                value: state.value === 1 ? QUALITY.ENABLE : QUALITY.DISABLE
            };

        case SET_DEFAULT_VALUE:
            return {
                ...state,
                value: payload.qualityFeature || state.value
            };

        case CLEAR_ORDERFORM:
            return {
                ...state,
                value: payload.qualityFeature
            };

        default:
            return state;
    }
}