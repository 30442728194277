import React from 'react';

export default function OrdersIcon(props) {
    return (
      <svg viewBox="0 0 16 16" {...props}>
            <g>
                <path d="M13.862,2C13.938,2,14,2.062,14,2.138v11.724C14,13.938,13.938,14,13.862,14H2.138
                    C2.062,14,2,13.938,2,13.862V2.138C2,2.062,2.062,2,2.138,2H13.862 M13.862,0H2.138C0.957,0,0,0.957,0,2.138v11.724
                    C0,15.043,0.957,16,2.138,16h11.724C15.043,16,16,15.043,16,13.862V2.138C16,0.957,15.043,0,13.862,0L13.862,0z"/>
                <g>
                    <rect x="4" y="4" width="4" height="4" />
                </g>
                <g>
                    <rect x="10" y="4" width="2" height="4" />
                </g>
                <g>
                    <rect x="4" y="10" width="8" height="2" />
                </g>
            </g>
        </svg>
    )
}
