import React, { useRef, useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';

/**
 * @name FormField
 * @component
 * @category Orderform
 * @subcategory Formfield
 * @hideconstructor
 */
function FormField({ className, id, type, name, placeholder, value, accept, disabled, error, handleChange, handleKeyPress, handleBlur, handleFocus, children }) {

    const myTextarea = useRef();
    const [calcHeight, setCalcHeight] = useState(60);

    useEffect(() => {
        if (myTextarea.current) {

            if (value === '') {
                setCalcHeight(60);

            } else if (myTextarea.current.offsetHeight < 150) {
                setCalcHeight(myTextarea.current.scrollHeight + 8);
            }
        }
    }, [value]);

    return (
        <div className="formField">
            {error && <span className="formField__label">{error}</span>}

            {(type !== 'textarea') ?
                <>
                    <input
                        className={className}
                        id={id}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        accept={accept}
                        disabled={disabled}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        tabIndex="1"
                        autoComplete="off"
                    />
                    {children}
                </> :
                <>
                    <textarea
                        className={className}
                        id={id}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        disabled={disabled}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        cols="30"
                        style={{ height: `${calcHeight}px` }}
                        ref={myTextarea}
                        tabIndex="1"
                    ></textarea>
                    {children}
                </>
            }
        </div>
    )
}

FormField.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    accept: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    error: PropTypes.string,
    onChange: PropTypes.func
}

export default memo(FormField);