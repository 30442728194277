import { Modal } from 'antd';
import { createBrowserHistory } from 'history';

// History for react Router
export const history = createBrowserHistory();

// router change
history.listen(() => {
  Modal.destroyAll();
});


export const redirectTo = async (path) => {
  if (!path) return;
  const _path = path.replace(/\/{2,}/g, '/').replace('*', ''); // cover some exceptions
  history.push(_path);
};
