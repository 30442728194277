/**
 * @module LoginActions
 */
import http from 'services/http';

import { customerURL, signinURL, signupURL } from 'store/config';

import { LOCK, SIGN_IN_FAILURE, SIGN_IN_SUCCESS, UNLOCK, USER_AUTHORIZED } from 'store/actionTypes';
import { fetchPreferredWriter } from 'store/orderform/details/preferredwriter/preferredWriterAction';
import { notify } from 'helpers/notification';
import { tokenService } from 'services/tokenService';
import { store } from 'index';
import { getFingerprint } from 'helpers/utils';
import { getReferrerId } from 'hooks/useReferralId';
import { APP_HOST, BASE_URL } from '../../constants';

/** */
export function checkUser() {
  return async function (dispatch) {
    const token = tokenService.getAccessToken();

    if (!token) return;

    return http
      .get(customerURL)
      .then((user) => {
        if (user?.id) {
          dispatch({
            type: USER_AUTHORIZED,
            payload: user,
          });

          fetchPreferredWriter()(dispatch);
        } else {
          console.log(user);
          return tokenService.clearTokens();
        }
      })
      .catch(console.error);
  };
}

export function checkMagicLogin() {
  return async function () {
    const searchParams = new URLSearchParams(window.location.search);
    const magicLink = searchParams.get('magicLink');

    if (!magicLink) return;

    console.log(`checkMagicLogin`, magicLink);

    return fetch(`${BASE_URL}/api/en/auth/jwt/magic_link/${magicLink}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.accessToken) {
          tokenService.setTokens(data);

          // Remove magicLink from search params
          setTimeout(() => {
            try {
            } catch (error) {
              console.error(error);
            }
          }, 100);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        searchParams.delete('magicLink'); // Remove used magicLink from search params
        const qs = searchParams.toString();
        const { pathname } = window.location;
        const url = `${pathname}${qs.length > 1 ? `?${qs}` : ''}`;
        window.history.replaceState(null, null, url);
      });
  };
}

export function login(credentials) {
  return async function (dispatch) {
    dispatch({ type: LOCK });
    return fetch(signinURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.accessToken) {
          dispatch({ type: SIGN_IN_FAILURE });
          throw new Error(data.message || 'failed');
        }
        http.setAccessToken(data.accessToken);
        tokenService.setTokens(data);
        dispatch({ type: SIGN_IN_SUCCESS });
        return credentials;
      })
      .catch(({ message }) => {
        notify.error(message); // show notification
        // * Reject without args, coz this is a main handler
        return Promise.reject();
      })
      .finally(() => {
        dispatch({ type: UNLOCK });
      });
  };
}

export function socialLogin(socialData, provider) {
  return async function (dispatch) {
    dispatch({ type: LOCK });

    const hashDevice = await getFingerprint();
    const referrerId = getReferrerId();

    let status;
    return fetch(`${BASE_URL}/api/en/auth/${provider}/sign_in`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...socialData,
        hashDevice,
        referrerId,
      }),
    })
      .then((response) => {
        status = response.status;
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        if (!data.accessToken) {
          dispatch({ type: SIGN_IN_FAILURE });
          throw new Error(data.message || 'failed');
        }
        http.setAccessToken(data.accessToken);
        tokenService.setTokens(data);
        dispatch({ type: SIGN_IN_SUCCESS });
        return socialData;
      })
      .catch(({ message }) => {
        notify.error(status === 400 ? message : 'Sorry, something went wrong. Try again.');
        // * Reject without args, coz this is a main handler
        return Promise.reject();
      })
      .finally(() => {
        dispatch({ type: UNLOCK });
      });
  };
}

export function logout() {
  // noinspection JSUnusedLocalSymbols
  return async (dispatch) => {
    console.log('L O G O U T [both]');

    await tokenService.clearTokens();

    if (APP_HOST.match(/localhost/)) return;

    console.log('Logout redirect >>>', `https://app.${APP_HOST}/profile?logout`);

    document.location.replace(`https://app.${APP_HOST}/profile?logout`);

    // dispatch({ type: LOGOUT_SUCCESS });
  };
}

export async function signUp(payload) {
  const hashDevice = await getFingerprint();
  const referrerId = getReferrerId();

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...payload,
      hashDevice,
      referrerId,
    }),
  };

  return fetch(signupURL, request)
    .then((resp) => resp.json())
    .then(async (tokens) => {
      // Check for server errors
      const { errors, message } = tokens;
      if (errors || message) {
        throw new Error(errors ? 'Check form fields' : message);
      }

      // Set tokens & update headers (http service)
      tokenService.setTokens(tokens);
      http.setAccessToken(tokens.accessToken);

      store.dispatch(checkUser()); // update store dashboard -> profile

      return tokens;
    });
}
