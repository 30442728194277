/**
 * 8 Oct, removed login page -> render UpgradePage instead
 * Login only from new app!
 */
import React from 'react'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import UpgradePage from '../pages/UpgradePage'

/**
 * @component
 * @hideconstructor
 */
export function PrivateRoute({ component: Component, ...rest }) {
  const isAuthorized = useSelector(({ global }) => global.isAuthorized)
  return (
    <Route {...rest}
           render={props => (
             isAuthorized ? <Component {...props} /> : <UpgradePage/>
           )}
    />
  )
}

export default PrivateRoute
