import { useLocation } from 'react-router';

export const useQueryString = (param) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  return params.get(param);
};

export const getValueFromQueryString = (param) => {
  const search = window.location.search;
  if (!search) return;
  const params = new URLSearchParams(search);
  return params.get(param);
};
