import { BASE_URL } from '../constants'

export const settingsURL = `${BASE_URL}/api/en/sites/settings`;
export const customerURL = `${BASE_URL}/api/en/customers/customer`;
export const signinURL = `${BASE_URL}/api/en/auth/jwt/sign_in`;
export const signupURL = `${BASE_URL}/api/en/auth/jwt/sign_up`;
export const checkmailURL = `${BASE_URL}/api/checkmail`;
export const deleteCustomersFilesURL = `${BASE_URL}/api/en/orders/customers/files`;
export const revisionCategoriesURL = `${BASE_URL}/api/en/orders/revisions/categories`;
export const revisionLevelsURL = `${BASE_URL}/api/en/orders/revisions/levels`;
export const referralLinkURL = `${BASE_URL}/api/en/customers/referral`;
export const updatePasswordURL = `${BASE_URL}/api/en/customers/customer/password`;
export const deactivateCustomerURL = `${BASE_URL}/api/en/customers/customer/deactivate`;
export const confirmEmailURL = `${BASE_URL}/api/en/customers/customer/emails/confirm`;
export const messengerToken = 'd9f902cabc5dbc3d55150abe1ee23534'; // without back_cms
export const paymentURL = {
    paymentwall: `${BASE_URL}/api/checkout/paymentwall/orders`,
    paycore: `${BASE_URL}/api/checkout/paycoreio/orders`,
    paypal: `${BASE_URL}/pp/sdk/redirect`,
    fondy: `${BASE_URL}/api/checkout/fondy/orders`,
    stripe: `${BASE_URL}/api/checkout/stripe/orders`,
}

export const facebookSDK = 'https://connect.facebook.net/en_US/sdk.js';

export function orderListURL(categoryId, params = {}) {
    const parameters = Object.entries(params).map(item => `${item[0]}=${item[1]}`).join('&');

    return `${BASE_URL}/api/en/sites/categories/${categoryId}/orders?${parameters}`;
}

export function orderinfoURL(orderId) {
    return `${BASE_URL}/api/en/orders/${orderId}`;
}

export function customersFilesURL(orderId) {
    return `${BASE_URL}/api/en/orders/${orderId}/customers/files`;
}

export function dowloadFilesURL(fileId) {
    return `${BASE_URL}/api/en/orders/files/${fileId}`
}

export function writersFilesURL(orderId) {
    return `${BASE_URL}/api/en/orders/${orderId}/writers/files`;
}

export function referralsURL(statusId) {
    if (statusId) {
        return `${BASE_URL}/api/en/customers/referrals/referral_customers?idStatus=${statusId}`;

    } else {
        return `${BASE_URL}/api/en/customers/referrals/referral_customers`;
    }
}

export function generateRefDiscountURL(refId) {
    return `${BASE_URL}/api/en/customers/referrals/${refId}/discount`;
}

export function revisionListURL(params = {}) {
    const parameters = Object.entries(params).map(item => `${item[0]}=${item[1]}`).join('&');

    return `${BASE_URL}/api/en/orders/revisions?${parameters}`;
}

export function revisionInfoByIdURL(revisionId) {
    return `${BASE_URL}/api/en/orders/revisions/${revisionId}`;
}

export function revisionCustomersFilesURL(revisionId) {
    return `${BASE_URL}/api/en/orders/revisions/${revisionId}/customers/files`;
}

export function revisionWritersFilesURL(revisionId) {
    return `${BASE_URL}/api/en/orders/revisions/${revisionId}/writers/files`;
}

export function createRevisionURL(orderId) {
    return `${BASE_URL}/api/en/orders/${orderId}/revisions`;
}

export function uploadRevisionFileURL(revisionId) {
    return `${BASE_URL}/api/en/orders/revisions/${revisionId}/customers/files`;
}

export function gdprURL(customerId) {
    return `${BASE_URL}/api/en/customers/customer/gdpr?id=${customerId}`;
}

export function addFeedbackURL(orderId) {
    return `${BASE_URL}/api/en/orders/${orderId}/reviews`;
}

export function updateOrderURL(categoryId, orderId) {
    return `${BASE_URL}/api/en/sites/categories/${categoryId}/orders/${orderId}`;
}

export function hideOrderURL(orderId) {
    return `${BASE_URL}/api/en/orders/${orderId}/hide`;
}

export function unhideOrderURL(orderId) {
    return `${BASE_URL}/api/en/orders/${orderId}/unhide`;
}

export function orderMessengerURL(domain, orderId) {
    return `${domain}/api/customers/orders/${orderId}/messages`;
}

export function orderMessengerUnreadURL(domain, orderId) {
    return `${domain}/api/customers/orders/${orderId}/messages/unread`;
}

export function orderMessengerUnreadListURL(domain) {
    return `${domain}/api/customers/orders/unread`;
}

export function orderMessengerReadURL(domain, orderId) {
    return `${domain}/api/customers/orders/${orderId}/messages/read`;
}

export function socketURL(socketDomain, token) {
    return `${socketDomain}/customers/orders/messages?accessToken=${token}`;
}

export const emojiList = [
    { id: '1', img: '😀 ' },
    { id: '2', img: '😃 ' },
    { id: '3', img: '😄 ' },
    { id: '4', img: '😁 ' },
    { id: '5', img: '😆 ' },
    { id: '6', img: '😅 ' },
    { id: '7', img: '😂 ' },
    { id: '8', img: '🤣 ' },
    { id: '9', img: '😊 ' },
    { id: '10', img: '😇 ' },
    { id: '11', img: '🙂 ' },
    { id: '12', img: '🙃 ' },
    { id: '13', img: '😉 ' },
    { id: '14', img: '😍 ' },
    { id: '15', img: '🥰 ' },
    { id: '16', img: '😘 ' },
    { id: '17', img: '😗 ' },
    { id: '18', img: '😋 ' },
    { id: '19', img: '😝 ' },
    { id: '20', img: '😜 ' },
    { id: '21', img: '🤨 ' },
    { id: '22', img: '😞 ' },
    { id: '23', img: '😟 ' },
    { id: '24', img: '😕 ' },
    { id: '25', img: '😯 ' },
    { id: '26', img: '😧 ' },
    { id: '27', img: '😥 ' },
    { id: '28', img: '😪 ' },
    { id: '29', img: '😏 ' },
    { id: '30', img: '🥳 ' },
    { id: '31', img: '😎 ' },
    { id: '32', img: '🤓 ' },
    { id: '33', img: '🤩 ' },
    { id: '34', img: '😓 ' },
    { id: '35', img: '😐 ' },
    { id: '36', img: '😑 ' },
    { id: '37', img: '😵 ' },
    { id: '38', img: '🤕 ' },
    { id: '39', img: '🤠 ' },
]

export const stickerList = [
    { id: '1', url: `/img/stickers/pencil_hi.gif` },
    { id: '2', url: `/img/stickers/pencil_no.gif` },
    { id: '3', url: `/img/stickers/pencil_RDFM.gif` },
    { id: '4', url: `/img/stickers/pencil_thank.gif` },
    { id: '5', url: `/img/stickers/pencil_yes.gif` },
]
