import React, { lazy, Suspense, useEffect, useState } from 'react';
import WS from 'services/ws';
import { history } from './helpers/history';
import { hexToRgb } from './helpers/utils';

// Store
import { useDispatch, useSelector } from 'react-redux';
import { checkMagicLogin, checkUser } from './store/login/loginActions';
import { fetchDefaultSettings } from './store/orderform/orderformglobal/globalAction';
import { getOrdersCount } from './store/dashboard/orderlist/ordersActions';
import { fetchDiscountList } from 'store/dashboard/loyalty/loyaltyActions';

// Routing
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { PrivateRoute } from './shared/PrivateRoute';

// Dashboard
import { MainLayout } from './dashboard/components/layout/MainLayout';
import { TabLinks } from './dashboard/components/tablinks/TabLinks';
import OrderinfoRedirect from './dashboard/OrderinfoRedirect';

import Modal from './shared/Modal';
import ErrorModal from './dashboard/ErrorModal';
import PrivacyModal from './dashboard/profile/privacymodal/PrivacyModal';
import PaymentModal from './dashboard/paymentmodal/PaymentModal';
import ForgotPassModal from './orderform/components/forgotpass/ForgotPassModal';
import FeedbackModal from './dashboard/components/rating/FeedbackModal';
import RevisionInfoModal from './dashboard/orderinfo/revisions/InfoModal';
import RevisionCreateModal from './dashboard/orderinfo/revisions/createmodal/CreateModal';

// Vendor features
import { ChatwootWidget } from './shared/chat/ChatwootWidget';
import { GoogleAnalytics } from './shared/GoogleAnalytics';
import { GTM } from './shared/GTM';
import { APP_HASH, APP_NAME, HOME_URL } from './constants';
import { getValueFromQueryString } from './hooks/useSearchParams';

// Lazy
// const Login = lazy(() => import('./pages/Login'))
const NotFound = lazy(() => import('./pages/NotFound'));
const ServerError = lazy(() => import('./pages/ServerError'));
const UpgradePage = lazy(() => import('./pages/UpgradePage'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Transactions = lazy(() => import('./pages/Transactions'));
const Orderinfo = lazy(() => import('./pages/Orderinfo'));

const modalComponents = {
  RevisionInfoModal,
  RevisionCreateModal,
  PrivacyModal,
  PaymentModal,
  FeedbackModal,
  ForgotPassModal,
  ErrorModal,
};

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const isAuthorized = useSelector(({ global }) => global.isAuthorized);
  const modalOpened = useSelector(({ global }) => global.modalOpened);
  const modalComponentName = useSelector(({ global }) => global.modalComponent);
  const modalInfo = useSelector(({ global }) => global.modalInfo);
  const mainColor = useSelector(({ global }) => global.mainColor);
  const links = useSelector(({ dashboard }) => dashboard.nav.links);
  const stepEnabled = useSelector(({ global }) => global.orderform?.stepEnabled);
  const defaultsFailedError = useSelector(({ global }) => global.defaultsFailedError);

  const instantLogout = getValueFromQueryString('logout');

  // Init app -> check tokens -> authorize user
  useEffect(() => {
    // Favicon
    let link = document.querySelector('link[rel~=\'icon\']');
    link.href = `${HOME_URL}/static/img/favicon/favicon.png`;

    // Update title
    document.title = `${APP_NAME} • Old Customer Dashboard`;

    if (instantLogout) {
      console.log('INSTANT LOGOUT', instantLogout);
      // dispatch(logout())
      return;
    }

    dispatch(fetchDefaultSettings())
      // Check magic login from qs ?magicLink=<string>
      .then(() => dispatch(checkMagicLogin()))

      // Verify tokens & check user
      .then(() => dispatch(checkUser()))

      .finally(() => setIsLoading());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mainColor) return;

    let colors = [
      ['--main-color', mainColor],
      ['--main-rgb-color', hexToRgb(mainColor)],
    ];

    // Update CSS variables
    colors.forEach(([name, value]) => document.documentElement.style.setProperty(name, value));
  }, [mainColor]);

  useEffect(() => {
    if (!isAuthorized) return;

    // Orders
    dispatch(getOrdersCount());

    // Loyalty
    dispatch(fetchDiscountList());

    WS.connect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  const InnerModalComponent = modalComponents[modalComponentName];

  if (defaultsFailedError) {
    return <ServerError/>;
  }

  return (
    <>
      <Router history={history}>
        <MainLayout>
          <main className="main dashboard" data-theme={APP_HASH}>
            <div className="container main-wrapper">
              {isAuthorized && (
                <nav className="nav">
                  <TabLinks links={links} baseUrl=""/>
                </nav>
              )}

              {!isLoading && (
                <section>
                  <Suspense fallback={null}>
                    <Switch>
                      <Route exact path="/">
                        {stepEnabled ? <Redirect to="/order/controls"/> : <Redirect to="/order"/>}
                      </Route>

                      <PrivateRoute path="/dashboard" component={Dashboard}/>
                      <PrivateRoute path="/transactions" component={Transactions}/>
                      <PrivateRoute path="/profile" component={UpgradePage}/>
                      <PrivateRoute path="/loyalty" component={UpgradePage}/>
                      <PrivateRoute path="/orderinfo/:id" component={Orderinfo}/>
                      <PrivateRoute exact path="/orderinfo" component={OrderinfoRedirect}/>

                      <Route path="/order" component={UpgradePage}/>
                      <Route path="/register" component={UpgradePage}/>
                      <Route path="/login" component={UpgradePage}/>
                      <Route path="/404" component={NotFound}/>
                      <Route exact path="/welcome" component={UpgradePage}/>

                      <Route component={NotFound}/>
                    </Switch>
                  </Suspense>
                </section>
              )}
            </div>
          </main>
        </MainLayout>

        <ChatwootWidget/>

        <GoogleAnalytics/>

        <GTM/>
      </Router>

      {modalOpened && (
        <Modal>
          <InnerModalComponent {...modalInfo} />
        </Modal>
      )}
    </>
  );
}

export default App;
