import React from 'react';

export default function FilesIcon() {
    return (
        <svg viewBox="0 0 16 16">
            <path d="M13.5,5v5.5c0,3.038-2.462,5.5-5.5,5.5h0
                c-3.038,0-5.5-2.462-5.5-5.5V4c0-2.209,1.791-4,4-4h0c2.209,0,4,1.791,4,4v5.5c0,1.381-1.119,2.5-2.5,2.5h0
                c-1.381,0-2.5-1.119-2.5-2.5V5c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v5h1V4c0-1.105-0.895-2-2-2h0c-1.105,0-2,0.895-2,2v6.5
                C4.5,12.433,6.067,14,8,14h0c1.933,0,3.5-1.567,3.5-3.5V5c0-0.552,0.448-1,1-1h0C13.052,4,13.5,4.448,13.5,5z"/>
        </svg>
    )
}
