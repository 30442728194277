import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { LoadingOutlined, LogoutOutlined, PlusSquareOutlined, UserOutlined } from '@ant-design/icons'
import { logout } from 'store/login/loginActions'
import { tokenService } from 'services/tokenService'
import { getValueFromQueryString } from '../../../hooks/useSearchParams'

export const HeaderMenu = () => {
  const dispatch = useDispatch()
  const isLocked = useSelector(({ global }) => global.locked)
  const customer = useSelector(({ dashboard }) => dashboard?.profile?.customer ?? {})
  const defaultsFailedError = useSelector(({ global }) => global.defaultsFailedError)
  const { firstName, email } = customer
  const isLoggedInBefore = tokenService.getAccessToken()
  const hasMagicLink = getValueFromQueryString('magicLink')

  const logoutUser = () => {
    dispatch(logout())
  }

  if (defaultsFailedError) return null // no spinner

  // Verifying user token
  if ((isLoggedInBefore && !email && !isLocked) || hasMagicLink) {
    return (
      <span>
        <LoadingOutlined style={{ fontSize: 33 }}/>
      </span>
    )
  }

  return (
    <div className="menu">
      <button className="menu__toggle">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <ul className="menu__list ">
        {isLoggedInBefore ? (
          <>
            <li className="menu__item">
              <UserOutlined/>
              <em>{firstName || email}</em>
            </li>
            <li className="menu__item">
              <div className="menu__link" onClick={logoutUser}>
                <LogoutOutlined/>
                <em>Logout</em>
              </div>
            </li>
          </>
        ) : (
          <>
            <li className="menu__item">
              <NavLink className="menu__link" to="/order">
                <PlusSquareOutlined/>
                <em>Create order</em>
              </NavLink>
            </li>
            <li className="menu__item">
              <NavLink className="menu__link" to="/login">
                <UserOutlined/> <em>Sign in</em>
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  )
}
