import {
  CLEAR_ORDERFORM,
  LOCK,
  LOGOUT_SUCCESS,
  SET_VIEW,
  SET_VIEW_ON_RESIZE,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
  START_CLONING_ORDER,
  START_EDITING_ORDER,
  TOGGLE_MODAL,
  UNLOCK,
  USER_AUTHORIZED,
} from 'store/actionTypes';

import {
  CREATE_ORDER,
  FETCH_DEFAULT_SETTINGS,
  FETCH_DEFAULT_SETTINGS_FAILED,
  FIRST_STEP_VALIDATION,
  SECOND_STEP_VALIDATION,
  SELECT_OPTION,
  SET_DEFAULT_VALUE,
  THIRD_STEP_VALIDATION,
  USER_SIGN_UP_SUCCESS,
} from 'store/orderform/orderformActionTypes';

export const initialState = {
  isAuthorized: false,
  locked: false,
  defaultsAlreadyFetched: false,
  defaultsFailedError: '',
  modalOpened: false,
  modalComponent: '',
  modalInfo: {},
  multipayment: false,
  messengerApiURL: '',
  orderMessengerApiURL: '',
  messengerWebsoketURL: '',
  messengerDisabled: false,
  // orderform state
  siteId: null,
  siteCategory: null,
  siteLanguage: 'EN',
  billingDescriptor: null,
  mainColor: '', // [CMS] gVar - application_theme, value - {"mainColor": "green"}
  siteDomain: '',
  supportEmail: '',
  signUpSuccess: false,
  firstStepValid: false,
  secondStepValid: false,
  thirdStepValid: false,
  yandexID: '', // [CMS] gVar - yandex_id
  yandexDebug: false,
  creatingOrder: false,
  paymentRules: {},
  enableExtraqualityCheck: false,
  enableOnePageSummary: false,
  predefaultState: {
    workType: '2',
    level: 1,
    paperFormat: 1,
    paperType: 1,
    resumeService: 1,
    subject: 0,
    pages: '1',
    spacing: 2,
    sources: '0',
    title: '',
    paperDetails: '',
    credentials: '',
    urgency: 240,
    resumeUrgency: 120,
    topWriters: 1,
    preferredWriters: 1,
    preferredWriterList: [],
    anotherWriter: true,
    qualityFeature: 0,
    extraqualityCheck: 0,
    onePageSummary: 0,
    discountCode: '',
  },
  editingOrderId: 0,
  editing: false,
  cloning: false,
  gridView: true,
  orderform: {
    stepEnabled: true, // check also utils/config.js -> essay
  },
  footerInfo: { enabled: false },
  chatwootToken: '',
  logo: '',
};

export default function globalReducer(state = initialState, { type, payload }) {
  switch (type) {
    // Dashboard
    case USER_AUTHORIZED:
      return {
        ...state,
        isAuthorized: true,
        thirdStepValid: true,
      };

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        isAuthorized: true,
      };

    case SIGN_IN_FAILURE:
      return {
        ...state,
        isAuthorized: false,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthorized: false,
      };

    case LOCK:
      return {
        ...state,
        locked: true,
      };

    case UNLOCK:
      return {
        ...state,
        locked: false,
      };

    case TOGGLE_MODAL:
      return {
        ...state,
        modalOpened: !state.modalOpened,
        modalComponent: (payload?.Component) || '',
        modalInfo: (payload?.modalInfo) || {},
      };

    case SET_VIEW:
      return {
        ...state,
        gridView: payload === 'grid' || false,
      };

    case SET_VIEW_ON_RESIZE:
      return {
        ...state,
        gridView: payload,
      };

    // Orderform
    case FETCH_DEFAULT_SETTINGS:
      return {
        ...state,
        siteCategory: payload.siteCategories[0],
        siteLanguage: payload.languageCode,
        billingDescriptor: payload.billingDescriptor,
        siteId: payload.idSite,
        mainColor: (payload.application_theme?.mainColor) || '',
        yandexID: payload.yandex_id || '',
        paymentRules: payload.payments_rules,
        siteDomain: payload.site_domain,
        supportEmail: payload.email,
        multipayment: payload.multipayment ? !!payload.multipayment : state.multipayment,
        messengerApiURL: payload.messages_url || '',
        orderMessengerApiURL: payload.baseOrderMessengerURL || '',
        messengerWebsoketURL: payload.baseWebSocketGatewayURL || '',
        messengerDisabled: payload.disableOrderMessenger || false,
        defaultsAlreadyFetched: true,
        enableExtraqualityCheck: payload.enableExtraQualityCheck || state.enableExtraqualityCheck,
        enableOnePageSummary: payload.enableOnePageSummary || state.enableOnePageSummary,

        // Nextgen sites
        dashboardUrl: payload.dashboardUrl,
        activeWorkTypes: payload.activeWorkTypes,
        orderFormConfig: payload.orderFormConfig, // override orderform config
        // Merge ordeform defaults
        predefaultState: {
          ...state.predefaultState,
          ...payload.preDefaultOrderFormState, // CMS -> Section "System variables" -> Key: "pre_default_order_form_state"
        },
        // Merge orderform config
        orderform: payload.orderFormConfig,

        footerInfo: {
          ...payload.footerInfo,
        },

        // Social Login IDs
        facebookAppId: payload.facebookAppId || false,
        googleClientId: payload.googleClientId || false,

        chatwootToken: payload.chatwootToken || '6YDBnEvSrbSxsUtGfXPwoSxQ', // TODO: remove before production!
        logo: payload.logo || '', // TODO: remove before production!
      };

    case FETCH_DEFAULT_SETTINGS_FAILED:
      return {
        ...state,
        defaultsFailedError: payload,
      };

    case USER_SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpSuccess: true,

      };

    case CREATE_ORDER:
      return {
        ...state,
        creatingOrder: !state.creatingOrder,
      };

    case SELECT_OPTION + '_subject':
      return {
        ...state,
        firstStepValid: true,
      };

    case FIRST_STEP_VALIDATION: // For Content category
      return {
        ...state,
        firstStepValid: true,
      };

    case SECOND_STEP_VALIDATION:
      return {
        ...state,
        secondStepValid: payload,
      };

    case THIRD_STEP_VALIDATION:
      return {
        ...state,
        thirdStepValid: payload,
      };

    case SET_DEFAULT_VALUE:
      return {
        ...state,
        yandexDebug: payload.yandexDebug || state.yandexDebug,
        editing: payload.editing || state.editing,
        cloning: payload.cloning || state.cloning,
        editingOrderId: payload.editingOrderId || state.editingOrderId,
      };

    case START_EDITING_ORDER:
      return {
        ...state,
        editing: true,
        editingOrderId: payload,
      };

    case START_CLONING_ORDER:
      return {
        ...state,
        cloning: true,
      };

    case CLEAR_ORDERFORM:
      return {
        ...state,
        editing: false,
        cloning: false,
        editingOrderId: 0,
      };

    default:
      return state;
  }
}
