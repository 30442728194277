import { camelize, hashCode, upperFirst } from './helpers/utils'

export const APP_HOST = window.location.host.replace(/^(app|old)\./, '')

export const APP_NAME = upperFirst(camelize(APP_HOST.replace(/\..+/, '')))
export const APP_HASH = hashCode(APP_NAME)
export const BASE_URL = process?.env.REACT_APP_BASE_URL || `https://old-api.${APP_HOST}` // LEGACY API
export const HOME_URL = process?.env.REACT_APP_HOME_URL || `https://${APP_HOST}` // HOMEPAGE
export const GA_CODE = process?.env.REACT_APP_GA_CODE || ''
export const GTM_CODE = process?.env.REACT_APP_GTM_CODE || ''
export const SITE_NAME = process?.env.REACT_APP_SITENAME
