import s from './MagicRedirect.module.scss';
import cn from 'classnames';
import { useMagicRedirect } from '../../hooks/useMagicRedirect';

export const MagicRedirect = ({ children }) => {
  const { isProcessing, magicRedirect } = useMagicRedirect();
  return (
    <span className={cn(s.defaultButton, { [s.locked]: isProcessing })} onClick={magicRedirect}>
      {children || 'Check it out!'}
    </span>
  );
};
