import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { loadChatScript } from './chat';
import { upperFirst } from '../../helpers/utils';

const _log = (...args) => console.log('%cChatwoot', 'background:purple;color:white;', ...args);

export const ChatwootWidget = () => {
  const customer = useSelector(({ dashboard }) => dashboard.profile.customer);
  const chatToken = useSelector(({ global }) => global.chatwootToken);

  const { pathname } = useLocation();
  const [prevUserId, setPrevUserId] = useState(customer.id); // fix some issues with magic link

  const [chatInitialized, setChatInitialized] = useState(false);
  const checkCounterRef = useRef(0);

  const signInUser = useCallback(() => {
    _log('Sign in');
    const userName = `${customer.firstName || ''} ${customer.lastName || ''}`.replaceAll('-', '').trim() || 'customer';

    const userInfo = {
      name: `#${customer.id} • ${userName}`,
      email: customer.email,
      phone_number: customer.phone ? `+${customer.phone}` : '',
      company_name: window.location.host.replace(/.*?\.(.*?)\..*/, '$1'),
    };

    window.$chatwoot.setUser(`uid ${customer.id}`, userInfo);

    customer?.status?.name &&
    window['$chatwoot']?.setCustomAttributes?.({ accountStatus: customer.status?.name });

    _log(`setUser [${customer.id}]`, userInfo);
  }, [customer]);
  const resetChat = useCallback(() => {
    if (!chatInitialized) return;
    window['$chatwoot'].reset();
  }, [chatInitialized]);

  /**
   * Sync chat with customer session / setUser
   */
  useEffect(() => {
    if (!chatToken) return;
    if (!chatInitialized) return _log('Not ready yet');

    if (customer.id !== prevUserId) {
      setPrevUserId(customer.id);
      if (prevUserId) {
        resetChat();
      }
    }

    if (customer.id && customer.id === prevUserId) {
      setTimeout(() => {
        signInUser();
      }, 1000);
    }
  }, [customer.id, prevUserId, chatInitialized, signInUser, resetChat, chatToken]);

  /**
   * Sync navigation / setCustomAttributes
   */
  const getTitleFromPath = (path) => {
    const pathValue = String(path).trim();
    if (!pathValue) return '';
    return upperFirst(pathValue.toLowerCase());
  };

  const setCustomAttributes = useCallback(() => {
    if (!chatInitialized) return;
    const { origin } = window.location;

    const path = {
      title: `${getTitleFromPath(pathname)}`,
      url: `${origin}${pathname}`,
    };

    window['$chatwoot']?.setCustomAttributes?.(path);
  }, [pathname, chatInitialized]);

  useEffect(setCustomAttributes, [pathname, setCustomAttributes]);

  /**
   * Check availability
   */
  useEffect(() => {
    if (!chatToken) return _log('Token is unavailable');

    let timer;

    const checkChatwoot = () => {
      checkCounterRef.current++;
      if (window['$chatwoot'] !== undefined && window['$chatwoot'].hasLoaded) {
        setChatInitialized(true);
      } else {
        if (checkCounterRef.current >= 10) return _log('Unavailable');
        timer = setTimeout(checkChatwoot, 500 * checkCounterRef.current);
      }
    };

    window.addEventListener('chatwoot:ready', checkChatwoot);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('chatwoot:ready', checkChatwoot);
    };
  }, [chatToken]);

  /**
   * Init chat on preferences fetched!
   */
  useEffect(() => {
    loadChatScript(chatToken || '');
  }, [chatToken]);

  return null;
};
