import { FETCH_LEVEL, FETCH_DEFAULT_SETTINGS, SET_DEFAULT_VALUE } from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';
import reducerLogic from 'store/orderform/controls/select/selectReducer';

export const initialState = {
    id: 'level',
    name: 'level',
    label: 'Academic Level',
    tooltip: 'Select an appropriate academic level. Depth/Complexity of research and wording may depend on it.',
    value: 1,
    error: '',
    searchValue: '',
    options: [],
    disabledOptions: [],
    filteredOptions: [],
    searchDisabled: true,
    dropdownOpen: false,
    selectDisabled: false,
    alreadyFetched: false
}

export default function levelReducer(state = initialState, action) {

    if (action.type === FETCH_LEVEL) {
        return {
            ...state,
            options: action.payload,
            alreadyFetched: true
        };

    } else if (action.type === FETCH_DEFAULT_SETTINGS) {

        if (action.payload.siteCategories[0] === 3) {
            return {
                ...state,
                label: 'Select level'
            }

        } else {
            return state;
        }

    } else if (action.type === SET_DEFAULT_VALUE) {
        return {
            ...state,
            value: action.payload.level || state.value
        }

    } else if (action.type === CLEAR_ORDERFORM) {
        return {
            ...state,
            value: action.payload.level
        }
    } else {
        return reducerLogic(state, action);
    }
}