/**
 * @module LoyaltyActions
 */
import http from 'services/http';
import { notify } from 'helpers/notification';
import { FETCH_LOYALTY_INFO } from 'store/actionTypes';
import { BASE_URL } from '../../../constants'

const discountsLoyalty = `${BASE_URL}/api/en/orders/discounts/loyalty`

export function fetchDiscountList() {
  return async function (dispatch) {
    return http
      .get(discountsLoyalty)
      .then((payload) => dispatch({ type: FETCH_LOYALTY_INFO, payload }))
      .catch((err) => console.error('[LOYALTY] Fetch discount', err));
  };
}

export function createDiscount() {
  return async function (dispatch) {
    return http
      .post(discountsLoyalty)
      .then((payload) => {
        notify.success('Created successfully');
        dispatch({ type: FETCH_LOYALTY_INFO, payload });
      })
      .catch((err) => console.error('[LOYALTY] Create discount', err));
  };
}
