import React, { useState } from 'react';

import Settings from './Settings';
import Preferences from './Preferences';
import PersonalInfo from './PersonalInfo';
import WipeModal from './WipeModal';

const tabs = {
    Settings,
    Preferences,
    PersonalInfo
}

/**
 * @name PrivacyModal
 * @component
 * @category Dashboard
 * @subcategory Profile
 * @hideconstructor
 */
export function PrivacyModal(props) {
    const [activeTab, setActiveTab] = useState('Settings');
    const [opened, setOpened] = useState(false);
    const Tab = tabs[activeTab];

    /**
     * @param {string} tabName
     */
    const setTab = (tabName) => {
        setActiveTab(tabName);
    }

    /** */
    const toggleOpen = () => {
        setOpened(!opened);
    }

    return (
        <>
            <div className="privacy">
                <Tab setTab={setTab} toggleOpen={toggleOpen} />
            </div>
            <WipeModal opened={opened} toggleOpen={toggleOpen} />
        </>
    )
}

export default PrivacyModal
