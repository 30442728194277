// !Draft
import { FETCH_REFERRALS_LIST, FETCH_REFERRER_DETAILS } from 'store/actionTypes';

const initialState = {
  // My referrer details
  details: {
    discount: {},
    order: {},
    referrer: { email: null, id: null },
  },
  // My referrals
  referrals: [],
};

export default function referralReducer(state = initialState, { type, payload }) {

  switch (type) {
    case FETCH_REFERRALS_LIST:
      return {
        ...state,
        referrals: [ ...payload ],
      };

      case FETCH_REFERRER_DETAILS:
      return {
        ...state,
        details: { ...payload },
      };

    default:
      return state;
  }
}

export const referralsListSelector = ({ dashboard }) => dashboard.referral.referrals;
export const referrerDetailsSelector = ({ dashboard }) => dashboard.referral.details;
