/**
 * @module OrdersActions
 */
import http from 'services/http';
import { FETCH_ORDER_LIST, SET_ORDER_LIST_PAGE, SET_ORDER_LIST_FILTER, SET_ORDER_LIST_SEARCH, GET_FILES_FOR_DOWNLOAD, CLEAR_FILES_FOR_DOWNLOAD, START_EDITING_ORDER, START_CLONING_ORDER, HIDE_ORDER, UNHIDE_ORDER, GET_ORDERS_COUNT, TOGGLE_HIDDEN_ORDERS, SET_DATE_FILTER, CLEAR_ALL_FILTERS, TOGGLE_FETCHITN_STATUS } from 'store/actionTypes';

import { orderListURL, writersFilesURL, updateOrderURL, hideOrderURL, unhideOrderURL } from 'store/config';
import { shouldRender, fileURL } from 'orderform/utils/config';
import { notify } from 'helpers/notification';
import { clearOrderform, lock, unlock } from 'store/global/globalActions';
import { getFingerprint, smoothScrollTop } from 'helpers/utils';
import { getCustomersFilesOnEdit } from 'store/orderform/details/file/fileAction';
import { deleteCustomersFilesURL } from 'store/config';
import { fetchOrderinfo } from 'store/dashboard/orderinfo/orderinfoActions';
import { redirectTo } from 'helpers/history';

/** */
export function fetchOrderList(config, e) {
    e && e.preventDefault();

    return function (dispatch, getState) {
        const { global: { siteCategory } } = getState();

        toggleOrderFetchingStatus(true)(dispatch);

        return http.get(orderListURL(siteCategory, config))
            .then(data => {
                dispatch({
                    type: FETCH_ORDER_LIST,
                    payload: data
                });
            })
            .catch(err => {
                console.log(err);
                notify.error('Something went wrong while fetching your order list. Please contact our support.');
            })
            .finally(() => {
                toggleOrderFetchingStatus(false)(dispatch);
            })
    }
}

/** */
export function getOrdersCount() {
    return function (dispatch, getState) {
        const { global: { siteCategory } } = getState();

        return http.get(orderListURL(siteCategory, { page: 1, limit: 12, q: '' }))
            .then(data => {
              const ordersCount = data?.pagination?.count || 0
                dispatch({
                    type: GET_ORDERS_COUNT,
                    payload: ordersCount
                });
              return ordersCount
            })
            .catch(console.error);
    }
}

/** */
export function setOrderListPage(pageNumber) {
    return function (dispatch) {
        setTimeout(smoothScrollTop, 1000); // on pagination

        dispatch({
            type: SET_ORDER_LIST_PAGE,
            payload: pageNumber
        });

    }
}

/** */
export function setOrderListFilter(statusId) {
    return function (dispatch) {

        dispatch({
            type: SET_ORDER_LIST_FILTER,
            payload: +statusId
        });
    }
}

/** */
export function setOrderListSearch(e) {
    return function (dispatch) {
        dispatch({
            type: SET_ORDER_LIST_SEARCH,
            payload: e.target.value
        });
    }
}

/** */
export function getFilesForDownload(orderId) {
    return function (dispatch) {
        return http.get(writersFilesURL(orderId))
            .then(data => {
                const list = data;

                list.forEach(item => {
                    item.typeName = 'Writer`s File';
                });

                dispatch({
                    type: GET_FILES_FOR_DOWNLOAD,
                    payload: { list, orderId }
                })

            })
            .catch(console.error)
    }
}

/** */
export function clearFilesForDownload(orderId) {
    return function (dispatch) {

        dispatch({
            type: CLEAR_FILES_FOR_DOWNLOAD,
            payload: orderId
        });
    }
}

/** */
export function cloneOrder(orderData, editing) {
    return function (dispatch) {
        const dataList = [
            { 'workType': orderData.workType.id.toString() },
            { 'level': orderData.academicLevel.id || orderData.resumeLevel.id || orderData.thesisLevel.id },
            { 'paperFormat': orderData.paperFormat.id },
            { 'paperType': orderData.assignment.id },
            { 'resumeService': orderData.resumeService.id },
            { 'subject': orderData.subject.id },
            { 'pages': orderData.pages.toString() },
            { 'id_spacing': orderData.paragraphSpacing.id },
            { 'sources': orderData.sources.toString() },
            { 'topic': encodeURI(orderData.title) },
            { 'description': editing ? encodeURI(orderData.description) : null },
            { 'credentials': editing ? encodeURI(orderData.credentials) : null },
            { 'urgency': orderData.urgency },
            { 'topWriters': orderData.features.premiumWriter ? 2 : 1 },
            { 'preferredList': orderData.preferredWritersList && orderData.preferredWritersList.length ? orderData.preferredWritersList : null },
            { 'anotherWriter': orderData.preferredWriterAnother.toString() },
            { 'extraqualityCheck': orderData.features.extraQualityCheck ? 2 : 1 },
            { 'onePageSummary': orderData.features.onePageSummary ? 2 : 1 },
            { 'qualityFeature': orderData.features.originalityReport ? 2 : 1 },
            { 'discountCode': orderData.discount.code },
            { 'editing': editing },
            { 'cloning': !editing }
        ];

        dataList.forEach(item => {
            const [element] = Object.entries(item);
            localStorage.removeItem(element[0]);

            if (element[1]) {
                let value;

                if (element[0] === 'preferredList') {
                    value = element[1].map(item => {
                        return item.id;
                    });

                } else {
                    value = element[1];
                }

                localStorage.setItem(element[0], value);
            }
        })

        if (editing) {
            dispatch({
                type: START_EDITING_ORDER,
                payload: orderData.id
            });

        } else {
            dispatch({ type: START_CLONING_ORDER });
        }
    }
}

/** */
export function updateOrder(orderId) {
    return async function (dispatch, getState) {
        const { global: { siteCategory, secondStepValid, thirdStepValid }, orderform: { workType, level, paperFormat, paperType, resumeService, subject, pages, sources, spacing, title, paperDetails, credentials, file, urgency, topWriters, preferredWriters, extraqualityCheck, qualityFeature, onePageSummary, discount } } = getState();

        if (!secondStepValid || !thirdStepValid) return;

        const workTypeVal = +workType.value;
        const levelVal = level.value;
        const paperFormatVal = paperFormat.value;
        const paperTypeVal = paperType.value;
        const resumeServiceVal = resumeService.value;
        const subjectVal = subject.value;
        const pagesVal = +pages.value;
        const sourcesVal = +sources.value;
        const spacingVal = spacing.value;
        const titleVal = title.value.replace(/(<([^>]+)>)/ig, "");
        const paperDetailsVal = paperDetails.value.replace(/(<([^>]+)>)/ig, "");
        const credentialsVal = credentials.value.replace(/(<([^>]+)>)/ig, "");
        const files = file.fileObjects.filter(item => !item.remote); //exclude remote files
        const deletedFiles = file.deletedFiles.filter(item => item);
        const urgencyVal = urgency.value;
        const topWritersVal = topWriters.value === 1 ? false : true;
        const preferredWritersVal = preferredWriters.preferredWriterList;
        const anotherWriterVal = preferredWriters.anotherWriter;
        const extraqualityCheckVal = extraqualityCheck.value === 1 ? false : true;
        const onePageSummaryVal = onePageSummary.value === 1 ? false : true;
        const qualityFeatureVal = qualityFeature.value === 1 ? false : true;
        const discountCodeVal = discount.code.replace(/(<([^>]+)>)/ig, "");

        const lvl = shouldRender(siteCategory, getState).levelFieldName;

        const data = {
            idWorkType: workTypeVal,
            [lvl]: levelVal,
            paperFormat: paperFormatVal,
            paperType: paperTypeVal,
            resumeService: resumeServiceVal,
            subject: subjectVal,
            discountCode: discountCodeVal,
            pages: pagesVal,
            sources: sourcesVal,
            idSpacing: spacingVal,
            topic: titleVal,
            paperDetails: paperDetailsVal,
            credentials: credentialsVal,
            urgency: urgencyVal,
            premiumWriter: topWritersVal,
            preferredWriterList: preferredWritersVal,
            preferredWriterAnother: anotherWriterVal,
            extraQualityCheck: extraqualityCheckVal,
            onePageSummary: onePageSummaryVal,
            originalityReport: qualityFeatureVal,
            premiumSupport: false
        }

        notify.wait('Your order is now being updated');
        lock()(dispatch);

        //add fingerprint hash
        return getFingerprint().then((hash) => {
            // Temporarily disabled
            // data.hashDevice = hash;

            return http
                .put(updateOrderURL(siteCategory, orderId), {
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                })
                .then(() => {
                    const fetches = [];

                    if (deletedFiles.length) {
                        deletedFiles.forEach(item => {
                            fetches.push(
                                http
                                    .delete(`${deleteCustomersFilesURL}/${item}`)
                                    .then(data => console.log('delete file', data))
                                    .catch(err => console.log('Remove Files', err))
                            )
                        })
                    }

                    return Promise
                        .all(fetches)
                        .then(() => {
                          const orderUrl = `/orderinfo/${orderId}/details`

                            if (files.length) {
                                const formData = new FormData();

                                files.forEach(file => {
                                    formData.append('file', file.fileObj);
                                });

                                // Sending files
                                return http
                                    .post(fileURL(orderId), { body: formData })
                                    .then(() => {
                                        redirectTo(orderUrl);
                                        clearOrderform()(dispatch, getState);
                                    })
                                    .catch((err) => {
                                        return Promise.reject(err);
                                    })

                            } else {
                                clearOrderform()(dispatch, getState);
                                redirectTo(orderUrl);
                            }
                            notify.success(`Success!`);
                        })
                        .catch((err) => {
                            notify.error('Failed.');
                            return Promise.reject(err);
                        })
                })
                .catch(({ message, body }) => {
                    if (body) {
                        console.error(body);

                        if (body.errors.discountCode && body.errors.discountCode.length) {
                            notify.error(body.errors.discountCode[0]);

                        } else if (body.errors.topic && body.errors.topic.length) {
                            notify.error('Please provide valid Title');
                            document.querySelector('#title') && document.querySelector('#title').focus();

                        } else if (body.errors.paperDetails && body.errors.paperDetails.length) {
                            notify.error('Paper Details must be empty or contain at least 10 characters');
                            document.querySelector('#paperDetails') && document.querySelector('#paperDetails').focus();

                        } else {
                            notify.error('Something went wrong');
                        }

                    } else {
                        console.log(message);
                    }

                    unlock()(dispatch);
                })


        });
    }
}

/** */
export function toggleHiddenOrders() {
    return function (dispatch) {
        dispatch({ type: TOGGLE_HIDDEN_ORDERS });
    }
}

/** */
export function hideOrder(orderId) {
    return function (dispatch) {
        return http
            .put(hideOrderURL(orderId), {
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id_order: orderId })
            })
            .then(data => dispatch({ type: HIDE_ORDER, payload: data.id }))
            .catch(err => console.error('Fail fetch [hideOrder]: ', err))
    }
}

/** */
export function unhideOrder(orderId) {
    return function (dispatch) {
        return http
            .put(unhideOrderURL(orderId), {
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id_order: orderId })
            })
            .then(data => dispatch({ type: UNHIDE_ORDER, payload: data.id }))
            .catch(err => console.error('Fail fetch [unhideOrder]: ', err))
    }
}

/** */
export function setDateFilter(value, label) {
    return function (dispatch) {
        dispatch({
            type: SET_DATE_FILTER,
            payload: { value, label }
        })
    }
}

/** */
export function clearAllFilters() {
    return function (dispatch) {
        dispatch({ type: CLEAR_ALL_FILTERS });
    }
}

/** */
export function toggleOrderFetchingStatus(status) {
    return function (dispatch) {
        dispatch({
            type: TOGGLE_FETCHITN_STATUS,
            payload: status
        })
    }
}

/** */
export function checkOrderMode(resolve) {
    return async function (dispatch, getState) {
        const { global: { editing, cloning } } = getState();
        const persistEdiging = localStorage.getItem('editing');
        const persistCloning = localStorage.getItem('cloning');

        const urlParams = new URLSearchParams(window.location.search);
        const editOrderId = urlParams.get('editingOrderId');
        const cloningOrder = urlParams.get('cloningOrder');

        // order?editingOrderId=130822&forceEditing=true&discountCode=fortest&magicLink=a58bfa6eb346706fd79ee8e99a555a37
        const forceEditing = urlParams.has('forceEditing');

        if (forceEditing && editOrderId) {
            await fetchOrderinfo(editOrderId)(dispatch);

            const { dashboard: { orderinfo: { order } } } = getState();

            if (order?.id) {
                exportFunctions.cloneOrder(order, true)(dispatch);
                getCustomersFilesOnEdit(editOrderId, resolve)(dispatch);

                return;
            }

            resolve && resolve();

        } else if (editing || cloning || persistEdiging || persistCloning) {
            editOrderId && getCustomersFilesOnEdit(editOrderId, resolve)(dispatch);
            cloningOrder && resolve && resolve();

            if (editOrderId || cloningOrder) return;

            clearOrderform()(dispatch, getState);
            resolve && resolve();

        } else {
            resolve && resolve();
        }
    }
}

// This solution is specially for testing
const exportFunctions = {
    fetchOrderList,
    getOrdersCount,
    setOrderListPage,
    setOrderListFilter,
    setOrderListSearch,
    getFilesForDownload,
    clearFilesForDownload,
    cloneOrder,
    updateOrder,
    toggleHiddenOrders,
    hideOrder,
    unhideOrder,
    setDateFilter,
    clearAllFilters,
    toggleOrderFetchingStatus,
    checkOrderMode
}

export default exportFunctions;
