// @ts-nocheck

/**
 * Chatwoot
 * Docs: https://www.chatwoot.com/docs/product/channels/live-chat/sdk/setup
 */

export const getChatActions = () => {
  const showChat = () => window['$chatwoot']?.toggle?.('show');

  return {
    showChat,
  };
};

export const loadChatScript = (websiteToken) => {
  if (!websiteToken) return;
  window.chatwootSettings = { position: 'right', type: 'standard', launcherTitle: 'Chat with us' };

  (function (d, t) {
    let BASE_URL = 'https://chat.cloudsend.pro';
    let g = d.createElement(t),
      s = d.getElementsByTagName(t)[0];
    g.src = BASE_URL + '/packs/js/sdk.js';
    g.defer = true;
    // @ts-ignore
    g.async = true;
    s.parentNode.insertBefore(g, s);
    g.onload = function () {
      window.chatwootSDK.run({
        websiteToken,
        baseUrl: BASE_URL,
      });
    };
  })(document, 'script');
};
