export const NapSkeleton = (props) => <svg viewBox="0 0 1278 834" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <g clipPath="url(#clip0_132_3755)">
    <rect width="1278" height="834" fill="#FBFBFB"/>
    <mask id="path-1-inside-1_132_3755" fill="white">
      <path d="M0 0H1278V64H0V0Z"/>
    </mask>
    <path d="M0 0H1278V64H0V0Z" fill="white"/>
    <rect x="54" y="15.5" width="72" height="33" rx="8" fill="currentColor" fillOpacity="0.04"/>
    <g filter="url(#filter0_d_132_3755)">
      <rect x="849" y="15" width="103" height="34" rx="6" fill="white"/>
      <rect x="849.5" y="15.5" width="102" height="33" rx="5.5" stroke="#EFEFEF"/>
    </g>
    <g filter="url(#filter1_d_132_3755)">
      <rect x="976" y="15" width="129" height="34" rx="6" fill="currentColor"/>
    </g>
    <rect x="1129.5" y="24.5" width="23" height="23" rx="3.5" fill="white" stroke="#EFEFEF"/>
    <rect x="1145" y="16" width="15" height="15" rx="7.5" fill="currentColor"/>
    <circle cx="1204" cy="32" r="20" fill="currentColor" fillOpacity="0.04"/>
    <path d="M1278 63H0V65H1278V63Z" fill="#EFEFEF" mask="url(#path-1-inside-1_132_3755)"/>
    <rect x="54" y="86" width="210" height="28" rx="6" fill="currentColor"/>
    <circle cx="292" cy="100" r="16" fill="currentColor" fillOpacity="0.04"/>
    <rect x="54" y="132" width="76" height="12" rx="4" fill="currentColor"/>
    <rect x="54.5" y="150.5" width="265.5" height="39" rx="5.5" fill="white" stroke="#E4E8F1"/>
    <rect x="336.5" y="132" width="76" height="12" rx="4" fill="currentColor"/>
    <rect x="337" y="150.5" width="265.5" height="39" rx="5.5" fill="white" stroke="#E4E8F1"/>
    <rect x="619" y="132" width="76" height="12" rx="4" fill="currentColor"/>
    <rect x="619.5" y="150.5" width="265.5" height="39" rx="5.5" fill="white" stroke="#E4E8F1"/>
    <rect x="901.5" y="132" width="76" height="12" rx="4" fill="currentColor"/>
    <rect x="902" y="150.5" width="265.5" height="39" rx="5.5" fill="white" stroke="#E4E8F1"/>
    <rect x="1184.5" y="150.5" width="39" height="39" rx="5.5" fill="white" stroke="#E4E8F1"/>
    <g filter="url(#filter2_d_132_3755)">
      <rect x="54" y="220" width="1170" height="160" rx="6" fill="white"/>
      <path d="M60 220.5H1218C1221.04 220.5 1223.5 222.962 1223.5 226V269.5H54.5V226C54.5 222.962 56.9624 220.5 60 220.5Z" fill="white"/>
      <rect x="70.5" y="234.5" width="75" height="21" rx="3.5" stroke="currentColor"/>
      <rect x="154" y="239" width="76" height="12" rx="4" fill="currentColor"/>
      <rect x="1060" y="241" width="148" height="8" rx="2" fill="currentColor" fillOpacity="0.04"/>
      <path d="M60 220.5H1218C1221.04 220.5 1223.5 222.962 1223.5 226V269.5H54.5V226C54.5 222.962 56.9624 220.5 60 220.5Z" stroke="#E4E8F1"/>
      <rect x="70" y="282" width="627" height="12" rx="4" fill="currentColor"/>
      <rect x="70" y="300" width="313" height="12" rx="4" fill="currentColor" fillOpacity="0.04"/>
      <rect x="389" y="304" width="4" height="4" rx="2" fill="currentColor" fillOpacity="0.04"/>
      <rect x="399" y="300" width="44" height="12" rx="4" fill="currentColor" fillOpacity="0.04"/>
      <rect x="70" y="324" width="114" height="12" rx="4" fill="currentColor" fillOpacity="0.04"/>
      <rect x="1088" y="282" width="120" height="8" rx="2" fill="currentColor" fillOpacity="0.04"/>
      <rect x="1144" y="296" width="64" height="16" rx="4" fill="currentColor"/>
      <g filter="url(#filter3_d_132_3755)">
        <rect x="1093" y="328" width="115" height="40" rx="6" fill="currentColor"/>
      </g>
      <rect x="54.5" y="220.5" width="1169" height="159" rx="5.5" stroke="#E4E8F1"/>
    </g>
    <g filter="url(#filter4_d_132_3755)">
      <rect x="54" y="396" width="1170" height="160" rx="6" fill="white"/>
      <path d="M60 396.5H1218C1221.04 396.5 1223.5 398.962 1223.5 402V445.5H54.5V402C54.5 398.962 56.9624 396.5 60 396.5Z" fill="white"/>
      <rect x="70.5" y="410.5" width="75" height="21" rx="3.5" stroke="currentColor"/>
      <rect x="154" y="415" width="76" height="12" rx="4" fill="currentColor"/>
      <rect x="1060" y="417" width="148" height="8" rx="2" fill="currentColor" fillOpacity="0.04"/>
      <path d="M60 396.5H1218C1221.04 396.5 1223.5 398.962 1223.5 402V445.5H54.5V402C54.5 398.962 56.9624 396.5 60 396.5Z" stroke="#E4E8F1"/>
      <rect x="70" y="458" width="627" height="12" rx="4" fill="currentColor"/>
      <rect x="70" y="476" width="313" height="12" rx="4" fill="currentColor" fillOpacity="0.04"/>
      <rect x="389" y="480" width="4" height="4" rx="2" fill="currentColor" fillOpacity="0.04"/>
      <rect x="399" y="476" width="44" height="12" rx="4" fill="currentColor" fillOpacity="0.04"/>
      <rect x="70" y="500" width="114" height="12" rx="4" fill="currentColor" fillOpacity="0.04"/>
      <rect x="1088" y="458" width="120" height="8" rx="2" fill="currentColor" fillOpacity="0.04"/>
      <rect x="1144" y="472" width="64" height="16" rx="4" fill="currentColor"/>
      <g filter="url(#filter5_d_132_3755)">
        <rect x="1093" y="504" width="115" height="40" rx="6" fill="currentColor"/>
      </g>
      <rect x="54.5" y="396.5" width="1169" height="159" rx="5.5" stroke="#E4E8F1"/>
    </g>
    <g filter="url(#filter6_d_132_3755)">
      <rect x="54" y="572" width="1170" height="160" rx="6" fill="white"/>
      <path d="M60 572.5H1218C1221.04 572.5 1223.5 574.962 1223.5 578V621.5H54.5V578C54.5 574.962 56.9624 572.5 60 572.5Z" fill="white"/>
      <rect x="70.5" y="586.5" width="75" height="21" rx="3.5" stroke="currentColor"/>
      <rect x="154" y="591" width="76" height="12" rx="4" fill="currentColor"/>
      <rect x="1060" y="593" width="148" height="8" rx="2" fill="currentColor" fillOpacity="0.04"/>
      <path d="M60 572.5H1218C1221.04 572.5 1223.5 574.962 1223.5 578V621.5H54.5V578C54.5 574.962 56.9624 572.5 60 572.5Z" stroke="#E4E8F1"/>
      <rect x="70" y="634" width="627" height="12" rx="4" fill="currentColor"/>
      <rect x="70" y="652" width="313" height="12" rx="4" fill="currentColor" fillOpacity="0.04"/>
      <rect x="389" y="656" width="4" height="4" rx="2" fill="currentColor" fillOpacity="0.04"/>
      <rect x="399" y="652" width="44" height="12" rx="4" fill="currentColor" fillOpacity="0.04"/>
      <rect x="70" y="676" width="114" height="12" rx="4" fill="currentColor" fillOpacity="0.04"/>
      <rect x="1088" y="634" width="120" height="8" rx="2" fill="currentColor" fillOpacity="0.04"/>
      <rect x="1144" y="648" width="64" height="16" rx="4" fill="currentColor"/>
      <g filter="url(#filter7_d_132_3755)">
        <rect x="1093" y="680" width="115" height="40" rx="6" fill="currentColor"/>
      </g>
      <rect x="54.5" y="572.5" width="1169" height="159" rx="5.5" stroke="#E4E8F1"/>
    </g>
    <rect x="54" y="762" width="32" height="32" rx="8" fill="currentColor" fillOpacity="0.04"/>
    <rect x="94" y="762" width="32" height="32" rx="4" fill="currentColor"/>
    <rect x="134.5" y="762.5" width="31" height="31" rx="3.5" fill="white" stroke="#E4E8F1"/>
    <rect x="174.5" y="762.5" width="31" height="31" rx="3.5" fill="white" stroke="#E4E8F1"/>
    <rect x="214.5" y="762.5" width="31" height="31" rx="3.5" fill="white" stroke="#E4E8F1"/>
    <rect x="254.5" y="762.5" width="31" height="31" rx="3.5" fill="white" stroke="#E4E8F1"/>
    <rect x="294" y="775.5" width="32" height="5" rx="2.5" fill="currentColor"/>
    <rect x="334.5" y="762.5" width="31" height="31" rx="3.5" fill="white" stroke="#E4E8F1"/>
    <rect x="374.5" y="762.5" width="31" height="31" rx="3.5" fill="white" stroke="currentColor"/>
  </g>
  <defs>
    <filter id="filter0_d_132_3755" x="843" y="12" width="115" height="46" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="3"/>
      <feGaussianBlur stdDeviation="3"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.870588 0 0 0 0 0.886275 0 0 0 0 0.929412 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_132_3755"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_132_3755" result="shape"/>
    </filter>
    <filter id="filter1_d_132_3755" x="970" y="12" width="141" height="46" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="3"/>
      <feGaussianBlur stdDeviation="3"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.376471 0 0 0 0 0.501961 0 0 0 0 0.839216 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_132_3755"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_132_3755" result="shape"/>
    </filter>
    <filter id="filter2_d_132_3755" x="44" y="214" width="1190" height="180" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_132_3755"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="4"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.654531 0 0 0 0 0.671125 0 0 0 0 0.7375 0 0 0 0.05 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_132_3755"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_132_3755" result="shape"/>
    </filter>
    <filter id="filter3_d_132_3755" x="1087" y="325" width="127" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="3"/>
      <feGaussianBlur stdDeviation="3"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.376471 0 0 0 0 0.501961 0 0 0 0 0.839216 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_132_3755"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_132_3755" result="shape"/>
    </filter>
    <filter id="filter4_d_132_3755" x="44" y="390" width="1190" height="180" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_132_3755"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="4"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.654531 0 0 0 0 0.671125 0 0 0 0 0.7375 0 0 0 0.05 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_132_3755"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_132_3755" result="shape"/>
    </filter>
    <filter id="filter5_d_132_3755" x="1087" y="501" width="127" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="3"/>
      <feGaussianBlur stdDeviation="3"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.376471 0 0 0 0 0.501961 0 0 0 0 0.839216 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_132_3755"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_132_3755" result="shape"/>
    </filter>
    <filter id="filter6_d_132_3755" x="44" y="566" width="1190" height="180" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_132_3755"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="4"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.654531 0 0 0 0 0.671125 0 0 0 0 0.7375 0 0 0 0.05 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_132_3755"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_132_3755" result="shape"/>
    </filter>
    <filter id="filter7_d_132_3755" x="1087" y="677" width="127" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="3"/>
      <feGaussianBlur stdDeviation="3"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.376471 0 0 0 0 0.501961 0 0 0 0 0.839216 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_132_3755"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_132_3755" result="shape"/>
    </filter>
    <clipPath id="clip0_132_3755">
      <rect width="1278" height="834" fill="white"/>
    </clipPath>
  </defs>
</svg>
