import { FETCH_DEFAULT_SETTINGS, SET_DEFAULT_VALUE } from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

import reducerLogic from 'store/orderform/textfield/textFieldReducer';

export const initialState = {
    id: 'title',
    type: 'text',
    value: '',
    error: '',
    placeholder: 'Writer’s Choice',
    label: 'Title',
    tooltip: 'Make sure the topic of your paper is short and clear. Leave it “Writer’s Choice” if you want us to choose.',
    isRequired: true,
    isFocused: false
}

export default function titleReducer(state = initialState, action) {

    if (action.type === SET_DEFAULT_VALUE) {
        return {
            ...state,
            value: action.payload.title || state.value
        };

    } else if (action.type === FETCH_DEFAULT_SETTINGS) {

        if (action.payload.siteCategories[0] === 3) {
            return {
                ...state,
                label: 'Desired position',
                placeholder: 'e.g., Sales manager, Marketing Executive'
            }

        } else if (action.payload.siteCategories[0] === 5) {
            return {
                ...state,
                placeholder: 'e.g., SEO Content. Keyword "chip essay"'
            }

        } else {
            return state;
        }

    } else if (action.type === CLEAR_ORDERFORM) {
        return {
            ...state,
            value: action.payload.title
        };

    } else {
        return reducerLogic(state, action);
    }
}