import { SET_DEFAULT_VALUE } from 'store/orderform/orderformActionTypes';

import reducerLogic from 'store/orderform/textfield/textFieldReducer';

export const initialState = {
    id: 'email',
    type: 'email',
    value: '',
    error: '',
    placeholder: 'Type in your email here',
    label: 'Email',
    tooltip: 'Enter a valid email address you have access to. It will be used to deliver completed paper.',
    isRequired: true,
    isFocused: false
}

export default function emailReducer(state = initialState, action) {

    if (action.type === SET_DEFAULT_VALUE) {
        return {
            ...state,
            value: action.payload.email || state.value
        };

    } else {
        return reducerLogic(state, action);
    }
}