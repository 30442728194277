import { combineReducers } from 'redux';

import profileReducer from 'store/dashboard/profile/profileReducer';
import navReducer from 'store/dashboard/nav/navReducer';
import ordersReducer from 'store/dashboard/orderlist/ordersReducer';
import orderinfoReducer from 'store/dashboard/orderinfo/orderinfoReducer';
import filesReducer from 'store/dashboard/orderinfo/files/filesReducer';
import revisionReducer from 'store/dashboard/orderinfo/revisions/revisionReduser';
import transactionsReducer from 'store/dashboard/transactionlist/transactionsReducer';
import feedbackReducer from 'store/dashboard/feedback/feedbackReducer';
import messengerReducer from 'store/dashboard/orderinfo/messenger/messengerReducer';
import orderMessengerReducer from 'store/dashboard/orderinfo/ordermessenger/orderMessengerReducer';
import loyaltyReducer from 'store/dashboard/loyalty/loyaltyReducer';
import referralReducer from 'store/dashboard/referral/referralReducer';

const dashboardReducer = combineReducers({
    profile: profileReducer,
    nav: navReducer,
    orders: ordersReducer,
    orderinfo: orderinfoReducer,
    files: filesReducer,
    revisions: revisionReducer,
    transactions: transactionsReducer,
    feedback: feedbackReducer,
    messages: messengerReducer,
    ordermessenger: orderMessengerReducer,
    loyalty: loyaltyReducer,
    referral: referralReducer,
});

export default dashboardReducer;
