/**
 * @module PreferredWriterActions
 */
import { FETCH_PREFERRED_WRITERS } from 'store/orderform/orderformActionTypes';

import { restoreOrderform } from 'store/orderform/orderformglobal/restoreAction';
import http from 'services/http';
import { preferredURL } from 'orderform/utils/config';

/** */
export function fetchPreferredWriter() {
    return function (dispatch, getState) {
        return http.get(preferredURL)
            .then(data => {
                if (Array.isArray(data)) {
                    const adaptArr = data.map(item => {

                        return {
                            id: item.idWriter,
                            name: `WR-${item.idWriter}`,
                            isPremium: item.isPremium
                        }
                    });

                    dispatch({
                        type: FETCH_PREFERRED_WRITERS,
                        payload: adaptArr
                    });

                    dispatch(restoreOrderform());

                } else {
                    dispatch({
                        type: FETCH_PREFERRED_WRITERS,
                        payload: []
                    });
                }
            })
            .catch(console.error)

        /*return fetch(preferredURL)
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    const adaptArr = data.map(item => {

                        return {
                            id: item.idWriter,
                            name: `WR-${item.idWriter}`,
                            isPremium: item.isPremium
                        }
                    });

                    dispatch({
                        type: FETCH_PREFERRED_WRITERS,
                        payload: adaptArr
                    });

                    dispatch(restoreOrderform());

                } else {
                    dispatch({
                        type: FETCH_PREFERRED_WRITERS,
                        payload: []
                    });
                }
            }).catch(console.error);*/
    }
}
