import { FETCH_LOYALTY_INFO } from 'store/actionTypes';

const initialState = {
  fetched: false,
  canCreate: false,
  canUse: false,
  pendingMilestonePrice: 0,
  totalPrice: 0,
  discount: {
    id: 0,
    code: '',
    percent: 0,
  },
  order: {
    id: 0,
    uId: '',
  },
};

export default function loyaltyReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_LOYALTY_INFO:
      return {
        ...state,
        ...payload,
        fetched: true,
      };

    default:
      return state;
  }
}

export const loyaltyDiscountSelector = ({ dashboard }) => dashboard.loyalty;
