import { useDispatch, useSelector } from 'react-redux'
import { LOCK, UNLOCK } from '../store/actionTypes'

export const useAppLock = () => {
  const dispatch = useDispatch()
  const isLocked = useSelector(({ global }) => global.locked)
  const lock = () => dispatch({ type: LOCK })
  const unlock = () => dispatch({ type: UNLOCK })
  return { isLocked, lock, unlock }
}
