import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PrivacyFooter from './PrivacyFooter';
import { gdprURL } from '../../../store/config';
import { checkBrowser } from '../../../helpers/utils';
import http from 'services/http';

/**
 * @component
 * @category Dashboard
 * @subcategory Profile
 * @hideconstructor
 * @memberof PrivacyModal
 */
function PersonalInfo({ profile, setTab, toggleOpen }) {
    const location = [
        { label: 'Country:', value: profile.country },
        { label: 'State:', value: profile.state },
        { label: 'City:', value: profile.city },
        { label: 'Address:', value: profile.address },
        { label: 'Zip:', value: profile.zip },
    ]

    const info = [
        { id: 'name', label: 'Name:', value: profile.firstName },
        { id: 'l-name', label: 'Last name:', value: profile.lastName },
        { id: 'mail', label: 'Email:', value: profile.email },
        { id: 'phone', label: 'Phone num:', value: profile.phone }
    ]

    /** */
    const handleDownloadFile = () => {
        http
            .get(gdprURL(profile.id), { responseType: 'blob' })
            .then(data => {
                const link = document.createElement("a");

                link.href = URL.createObjectURL(data);
                link.setAttribute("download", 'report');
                link.click();
            })
            .catch(console.error)

    }

    return (
        <div className="privacy__personal-wrapper">
            <h2 className="privacy__heading">Privacy settings &gt; Personal data</h2>
            <p className="privacy__subheading">
                Here we display all the data we collect about you. This includes technical and personal information. You can also download detailed report regarding what exactly is being collected. Additionally you can modify some of this data or remove your account and all related data completely.
            </p>
            <div className="privacy__stats-wrapper">
                <div className="privacy__stats-left">
                    <ul className="privacy__list clean">
                        <li className="privacy__list-item">
                            <span className="label">IP:</span>
                            <span id="ip-address">
                                {profile.enabledTechnicalData ? profile.ipClient : "-"}
                            </span>
                        </li>
                        <li className="privacy__list-item">
                            <span className="label">Browser:</span>
                            <span id="browser-type">
                                {profile.enabledTechnicalData ? checkBrowser() : "-"}
                            </span>
                        </li>
                        <li className="privacy__list-item">
                            <span className="label">Location</span>
                            <ul className="privacy__list nested">
                                {location.map((item, index) => (
                                    <li key={index}>
                                        <span>{item.label}</span>
                                        <span id={`customer-${item.label.toLowerCase()}`}>{item.value || '-'}</span>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </div>

                <div className="privacy__stats-right">
                    <ul className="privacy__list clean">
                        {info.map(item => (
                            <li className="privacy__list-item" key={item.id}>
                                <span className="label">{item.label}</span>
                                <span id={`customer-${item.id}`}>{item.value || '-'}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="privacy__btn-wrapper">
                <button
                    className="privacy__download-btn"
                    onClick={handleDownloadFile}
                >
                    Download all in file
                </button>
                <button
                    className="privacy__delete-btn"
                    onClick={toggleOpen}
                >
                    I want to delete my account
                </button>
            </div>

            <PrivacyFooter backBtn={true} setTab={setTab} />
        </div>
    )
}

PersonalInfo.propTypes = {
    profile: PropTypes.object,
    setTab: PropTypes.func,
    toggleOpen: PropTypes.func
}

const mapStateToProps = ({ dashboard }) => ({
    profile: dashboard.profile.customer
});

export default connect(mapStateToProps)(PersonalInfo);
