import { FETCH_PAPER_FORMAT, SET_DEFAULT_VALUE } from "store/orderform/orderformActionTypes";
import { CLEAR_ORDERFORM } from 'store/actionTypes';
import reducerLogic from 'store/orderform/controls/select/selectReducer';

export const initialState = {
    id: 'paperFormat',
    name: 'paperFormat',
    label: 'Paper Format',
    tooltip: 'Select the required or preferred citation style for your assignment.',
    value: 1,
    error: '',
    searchValue: '',
    options: [],
    disabledOptions: [],
    filteredOptions: [],
    searchDisabled: true,
    dropdownOpen: false,
    selectDisabled: false,
    alreadyFetched: false
}

export default function paperFormatReducer(state = initialState, action) {

    if (action.type === FETCH_PAPER_FORMAT) {
        return {
            ...state,
            options: action.payload,
            alreadyFetched: true
        };

    } else if (action.type === SET_DEFAULT_VALUE) {
        return {
            ...state,
            value: action.payload.paperFormat || state.value
        }

    } else if (action.type === CLEAR_ORDERFORM) {
        return {
            ...state,
            value: action.payload.paperFormat
        }

    } else {
        return reducerLogic(state, action);
    }
}