import { Checkbox } from 'antd';
import { NapSkeleton } from './NapSkeleton';
import { useUpgradePage } from '../hooks/useUpgradePage';
import { BrowserRouter, Redirect, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppLock } from '../hooks/useAppLock';
import { SpinFullscreen } from '../shared/SpinFullscreen';
import { MagicRedirect } from '../dashboard/upgrade/MagicRedirect';

import s from './UpgradePage.module.scss';
import { useSelector } from 'react-redux';

export const UpgradePage = () => {
  const isAuthorized = useSelector(({ global }) => global.isAuthorized);
  const { toggleSkip, skip } = useUpgradePage();

  const { pathname } = useLocation();

  const { lock, unlock } = useAppLock();

  const buttonText = {
    '/order': 'Create New Order',
    '/login': 'Proceed Login',
    '/profile': 'Check my Profile',
    '/loyalty': 'Check my Bonuses',
  }[window.location.pathname];

  useEffect(() => {
    if (!skip) return;
    lock();
    return unlock;
  }, [lock, skip, unlock]);

  if (isAuthorized && skip) return <SpinFullscreen />;

  if (pathname === '/login') return <Redirect to="/dashboard" />;

  // Redirect with countdown info [useless?]
  // if (skip) return <div className={s.redirect}>
  //   <div>
  //     <h1>{redirectCountDown}</h1>
  //     <div>
  //       Redirecting to {targetLink}...
  //       <br/>
  //       <Checkbox defaultChecked={skip} onChange={toggleSkip}>Enabled</Checkbox>
  //     </div>
  //   </div>
  // </div>

  return (
    <div className={s.wrapper}>
      <BrowserRouter>
        <div className={s.left}>
          <NapSkeleton className={s.skeleton} />
        </div>
        <div className={s.right}>
          <h1 className={s.heading}>
            <img className={s.hello} src="/img/hello.webp" alt="" />
            <span>Hey there!</span>
          </h1>
          <p className={s.text}>
            We have a <em className="underline">new version</em> of application available.
          </p>
          <p className={s.alert}>
            You can still manage your old orders, but we recommend you saving all your important data as soon
            as you can.
          </p>
          <footer className={s.footer}>
            <MagicRedirect>{buttonText || 'Check it out'}</MagicRedirect>
            {isAuthorized && (
              <Checkbox defaultChecked={skip} onChange={toggleSkip}>
                Don't show again
              </Checkbox>
            )}
          </footer>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default UpgradePage;
