import { FETCH_SUBJECT, SET_DEFAULT_VALUE } from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';
import reducerLogic from 'store/orderform/controls/select/selectReducer';

export const initialState = {
    id: 'subject',
    name: 'subject',
    label: 'Subject',
    tooltip: 'Select the subject you study. If the necessary subject is not listed, contact us via the live chat and we will help you choose.',
    value: 0,
    error: '',
    searchValue: '',
    options: [
        { id: 0, name: 'Please select subject' }
    ],
    disabledOptions: [],
    filteredOptions: [],
    searchDisabled: false,
    dropdownOpen: false,
    selectDisabled: false,
    isRequired: true,
    alreadyFetched: false
}

export default function subjectReducer(state = initialState, action) {

    if (action.type === FETCH_SUBJECT) {
        return {
            ...state,
            options: [
                ...state.options,
                ...action.payload
            ],
            alreadyFetched: true
        };

    } else if (action.type === SET_DEFAULT_VALUE) {
        return {
            ...state,
            value: action.payload.subject || state.value
        }
    } else if (action.type === CLEAR_ORDERFORM) {
        return {
            ...state,
            value: action.payload.subject
        }
    } else {
        return reducerLogic(state, action);
    }
}