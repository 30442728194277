/**
 * @module ProfileActions
 */
import {
    SET_PROFILE_PHONE,
    SET_PROFILE_FIRST_NAME,
    TOGGLE_PROFILE_NEWS_LETTERS,
    TOGGLE_PROFILE_NIGHT_CALLS,
    UPDATE_PROFILE,
    SET_PREFERENCES,
    DELETE_PROFILE,
    SEND_CONFIRMATION,
    TOGGLE_PROFILE_FB_MESSENGER,
    DISABLED_PROFILE_FB_MESSENGER,
    ENABLED_PROFILE_FB_MESSENGER,
    LOADING_FACEBOOK_SCRIPTS,
    UPDATE_PROFILE_STATUS
} from "store/actionTypes";

import { customerURL, gdprURL, deactivateCustomerURL, confirmEmailURL } from 'store/config';
import { notify } from 'helpers/notification';
import { checkUser } from 'store/login/loginActions';
import { lock, unlock } from "store/global/globalActions";
import http from "services/http";

/** */
export function setProfilePhone(e) {
    return function (dispatch) {

        if (e.target.value.search(/[^0-9]/g) !== -1) {
            return;
        } else if (e.target.value.length > 21) {
            return;
        }

        dispatch({
            type: SET_PROFILE_PHONE,
            payload: e.target.value
        })
    }
}

/** */
export function setProfileFirstName(e) {
    return function (dispatch) {
        dispatch({
            type: SET_PROFILE_FIRST_NAME,
            payload: e.target.value
        })
    }
}

/** */
export function toggleProfileNewsLetters(e) {
    return function (dispatch) {
        dispatch({ type: TOGGLE_PROFILE_NEWS_LETTERS })
    }
}

/** */
export function toggleProfileCalls(e) {
    return function (dispatch) {
        dispatch({ type: TOGGLE_PROFILE_NIGHT_CALLS })
    }
}

/** */
export function updateProfile() {
    return function (dispatch, getState) {
        const { dashboard: { profile } } = getState();

        const bodyData = {
            enabledFacebookMessenger: profile.customer.enabledFacebookMessenger,
            enabledNewsletters: profile.customer.enabledNewsletters,
            enabledNightCalls: profile.customer.enabledNightCalls,
            firstName: profile.customer.firstName,
            lastName: profile.customer.lastName,
            phone: profile.customer.phone
        }

        lock()(dispatch);

        window.FB && window.FB.AppEvents.logEvent('MessengerCheckboxUserConfirmation', null, {
            'app_id': profile.customer.facebookData?.fbMessengerAppId,
            'page_id': profile.customer.facebookData?.fbPageId,
            'ref': profile.customer.facebookData?.ref,
            'user_ref': profile.customer.facebookData?.userRef
        });

        return http
            .put(customerURL, {
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(bodyData)
            })
            .then(data => {
                if (data.id) {
                    dispatch({
                        type: UPDATE_PROFILE,
                        payload: data
                    })

                    notify.success('Your personal information has been successfully updated.');
                    dispatch(facebookCkeckboxInit());
                }
            })
            .catch(err => {
                console.log(err);
                notify.error('Something went wrong');
            })
            .finally(() => unlock()(dispatch))

        // return fetch(customerURL, {
        //     method: 'PUT',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(bodyData)
        // })
        //     .then(response => response.json())
        //     .then(data => {

        //         if (data.id) {
        //             dispatch({
        //                 type: UPDATE_PROFILE,
        //                 payload: data
        //             })

        //             notify.success('Your personal information has been successfully updated.');
        //             dispatch(facebookCkeckboxInit());
        //         }
        //     })
        //     .catch(err => {
        //         console.log(err);
        //         notify.error('Something went wrong');
        //     })
        //     .finally(() => {
        //         unlock()(dispatch);
        //     })
    }
}

/** */
export function setPreferences(fieldName) {
    return function (dispatch, getState) {
        dispatch({
            type: SET_PREFERENCES,
            payload: fieldName
        })

        const { dashboard: { profile: { customer } } } = getState();

        // TODO: CHECK THIS
        const bodyData = {
            cookies_usages: +customer.enabledCookiesUsage,
            personal_data: +customer.enabledPersonalData,
            technical_data: +customer.enabledTechnicalData,
            location_usages: +customer.enabledLocationUsage,
            information_sharing: +customer.enabledInformationSharing,
            email_subscription: +customer.enabledEmailSubscription
        }

        return http
            .put(gdprURL(customer.id), {
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(bodyData)
            })
            .then(res => res.customer && checkUser()(dispatch))
            .catch(({ message, body }) => {
                if (body) {
                    dispatch({
                        type: SET_PREFERENCES,
                        payload: fieldName
                    });

                    notify.warn('Problem occurred while setting your preferences');

                } else {
                    console.log(message);
                }
            })

    }
}

/** */
export function deleteProfile(closeFn) {
    return function (dispatch) {
        return http
            .put(deactivateCustomerURL)
            .then(() => {
                notify.info('Your account has been successfully deleted.');
                dispatch({ type: DELETE_PROFILE })

                setTimeout(function () {
                    window.location.href = '/logout'
                }, 3000);
            })
            .catch(({ message, body }) => {
                if (body) {
                    console.error(body.text);
                    notify.error('Email is not confirmed');

                } else {
                    console.log(message);
                    notify.error('Error');
                }
            })
            .finally(() => closeFn())

    }
}

/** */
export function confirmEmail(email) {
    return function (dispatch) {
        return http
            .post(confirmEmailURL)
            .then(res => {
                if (res.message === 'Success') {
                    notify.info(`A confirmation email has been sent to ${email}`)
                    dispatch({ type: SEND_CONFIRMATION })
                }
            })
            .catch(console.error)

    }
}

/** */
export function toggleFBMessenger(e) {
    return function (dispatch) {
        dispatch({ type: TOGGLE_PROFILE_FB_MESSENGER });
    }
}

/** */
export function enableFBMessenger(e) {
    return function (dispatch) {
        dispatch({ type: ENABLED_PROFILE_FB_MESSENGER });
    }
}

/** */
export function disableFBMessenger(e) {
    return function (dispatch) {
        dispatch({ type: DISABLED_PROFILE_FB_MESSENGER });
    }
}

/** */
export function facebookCkeckboxInit(e) {

    return function (dispatch, getState) {

        const { dashboard: { profile } } = getState();

        // Disabled facebook ckeck-box, enabled custom check-box
        if (profile.customer.facebookData?.isChecked) return

        (window.fbAsyncInit = function () {

            window.FB && window.FB.init({
                appId: profile.customer.facebookData?.fbMessengerAppId,
                xfbml: true,
                version: 'v2.6'
            });

            console.log('%c Init Facebook Messenger', 'color:#fff;background:#4285EF');

            window.FB && window.FB.Event.subscribe('messenger_checkbox', function (e) {
                // console.log(e)
                disableFBMessenger()(dispatch);
                if (e.event === 'checkbox') {

                    if (e.state === 'checked') {

                        window.FB.AppEvents.logEvent('MessengerCheckboxUserConfirmation', null, {
                            'app_id': profile.customer.facebookData?.fbMessengerAppId,
                            'page_id': profile.customer.facebookData?.fbPageId,
                            'ref': profile.customer.facebookData?.ref,
                            'user_ref': profile.customer.facebookData?.userRef
                        });

                        // console.log('checked')
                        enableFBMessenger()(dispatch);

                    } else if (e.state === 'unchecked') {
                        // console.log('unchecked')
                        disableFBMessenger()(dispatch);
                    }

                }
            });
        })();

    }
}

/** */
export function facebookScriptIncludeStatus() {
    return function (dispatch) {
        dispatch({ type: LOADING_FACEBOOK_SCRIPTS })
    }
}

/** */
export function updateUserProfile() {
    return function (dispatch, getState) {
        const { dashboard: { profile: { customer, profileData } } } = getState();

        if (profileData.phone !== customer.phone ||
            profileData.name !== customer.firstName ||
            profileData.letters !== customer.enabledNewsletters ||
            profileData.calls !== customer.enabledNightCalls ||
            profileData.facebookCkeckbox !== customer.enabledFacebookMessenger) {

            dispatch({
                type: UPDATE_PROFILE_STATUS,
                payload: true
            });

        } else {

            dispatch({
                type: UPDATE_PROFILE_STATUS,
                payload: false
            });

        }
    }
}
