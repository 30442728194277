import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormGroup from '../formfield/FormGroup';
import { toggleModal } from '../../../store/global/globalActions';
import { emailValidation } from '../../utils/orderformUtils';
import { notify, notification } from '../../../helpers/notification';
import { BASE_URL } from '../../../constants'


/**
 * @component
 * @category Orderform
 * @subcategory Forgotpassword
 * @hideconstructor
 */
function ForgotPassModal({ emailValue, supportEmail, siteDomain, toggleModal }) {
    const [{ value, isFocused }, setState] = useState({ value: emailValue, isFocused: false });

    const emailConfig = {
        label: 'Please send my new password to the mail:',
        isRequired: false,
        tooltip: null,
        id: 'reset-password',
        type: 'email',
        placeholder: 'Type in your email here',
        value: value,
        error: '',
        isFocused: isFocused
    };

    /**
     * @param {Event} e
     */
    const handleSetEmail = (e) => {
        e.persist();
        setState(prevProps => ({ ...prevProps, value: e.target.value }));
    }

    /**
     * @param {Event} e
     */
    const handleResetPass = (e) => {
        e.preventDefault();

        if (emailValidation(value)) {
            const requestData = new FormData();
            requestData.append('mail', value);

            fetch(`${BASE_URL}/forgotpassword`, {
                method: 'POST',
                body: requestData
            })
                .then(response => response.json())
                .then(responseCode => {
                    if (responseCode === 0) {
                        notification.success({ message: 'Check your email for a new password.', duration: 4});
                        toggleModal();
                    } else {
                      notify.error('Invalid email address.');
                    }
                }, err => {
                  console.error('Fail fatch [forgotpassword]: ', err);
                })

        } else {
            notify.warn('An email field is mandatory.');
        }
    }

    /**
     * @param {Event} e
     */
    const handleSendMagicLink = (e) => {
        e.preventDefault();

        if (emailValidation(value)) {
            const requestData = new FormData();
            requestData.append('email', value);

            // http
            //     .post(`${BASE_URL}/api/magic_link`, { body: requestData })
            //     .then(() => {
            //         notify.success(`We sent an email to ${value}.
            //             If this email address has an account on ${siteDomain.replace('https://')},
            //             you’ll find a magic link that will sign you into your account.
            //             The link expires in 12 hours, so be sure to use it soon.
            //             Go check your email!`, 'An email is on its way!');
            //     })
            //     .catch(({ message, body }) => {
            //         if (body) {
            //             notify.error('Email doesn\'t exist on site');

            //         } else {
            //             console.error('Fail fetch [Magic Link]: ', message);
            //             notify.error('An error occurred while checking your email. Please contact us via email or live  for resolution.');
            //         }
            //     })

            fetch(`${BASE_URL}/api/magic_link`, {
                method: 'POST',
                body: requestData
            })
                .then(response => {
                  if (response.ok) {
                    notification.success({
                        message: 'An email is on its way!',
                        description: `We sent an email to ${value}.
                        If this email address has an account on ${siteDomain.replace('https://','')},
                        you’ll find a magic link that will sign you into your account.
                        The link expires in 12 hours, so be sure to use it soon.
                        Go check your email!`
                    });
                    toggleModal();
                  } else if (response.status === 404) {
                        notify.error('Email doesn\'t exist on site');
                  } else {
                    notification.error({ message: 'Error', description: 'An error occurred while checking your email. Please contact us via email or live  for resolution.' });
                  }
                }, err => {
                    console.error('Fail fetch [Magic Link]: ', err);
                })

        } else {
            notify.warn('An email field is mandatory.');
        }
    }

    const handleFocus = () => {
        setState(prevProps => ({ ...prevProps, isFocused: true }));
    }

    const handleBlur = () => {
        setState(prevProps => ({ ...prevProps, isFocused: false }));
    }

    return (
        <div className="forgot-modal">
            <button
                className="forgot-modal__exit"
                onClick={toggleModal}
            >
                <svg width="14" height="14" viewBox="0 0 14 14"><g><path d="M.636 11.95L5.586 7 .636 2.05 2.05.636 7 5.586l4.95-4.95 1.414 1.414L8.414 7l4.95 4.95-1.415 1.414L7 8.414l-4.95 4.95z" /></g></svg>
            </button>
            <div className="forgot-modal__body">
                <div className="forgot-modal__header">
                    Forgot your password?
                </div>
                <div className="forgot-modal__text">
                    You may need to check the junk mail folder or unlock the address: <strong>{supportEmail}</strong>
                </div>
                <FormGroup
                    data={emailConfig}
                    handleChange={handleSetEmail}
                    handleBlur={handleBlur}
                    handleFocus={handleFocus}
                />
            </div>
            <div className="forgot-modal__footer">
                <div className="forgot-modal-col">
                    <button
                        className="forgot-modal__submit reset-pass"
                        onClick={handleResetPass}
                    >
                        Reset password
                </button>
                    <button
                        className="forgot-modal__submit magic-link"
                        onClick={handleSendMagicLink}
                    >
                        Send Me a Magic Link
                </button>
                </div>
                <div className="forgot-modal-col">
                    <button
                        className="forgot-modal__close"
                        onClick={toggleModal}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}

ForgotPassModal.propTypes = {
    emailValue: PropTypes.string,
    supportEmail: PropTypes.string,
    siteDomain: PropTypes.string,
    toggleModal: PropTypes.func,
}

const mapStateToProprs = (state) => ({
    emailValue: state.orderform.email.value || state.dashboard.profile.customer.email,
    supportEmail: state.global.supportEmail,
    siteDomain: state.global.siteDomain
})

const mapDispatchToProps = {
    toggleModal
}

export default connect(mapStateToProprs, mapDispatchToProps)(ForgotPassModal)
