import React from 'react';

export default function MessagesIcon() {
    return (
        <svg viewBox="0 0 16 16">
            <g>
                <path d="M13.327,0H2.673C1.197,0,0,1.197,0,2.673v6.655C0,10.803,1.197,12,2.673,12h10.655
                    C14.803,12,16,10.803,16,9.327V2.673C16,1.197,14.803,0,13.327,0z M12.333,2L8,5.75L3.667,2H12.333z M13.327,10H2.673
                    C2.302,10,2,9.698,2,9.327V3.25L7.4,7.8C7.578,7.933,7.789,8,8,8s0.422-0.067,0.6-0.2L14,3.25v6.078C14,9.698,13.698,10,13.327,10z
                    "/>
                <path d="M15,16H1c-0.552,0-1-0.448-1-1v0c0-0.552,0.448-1,1-1h14c0.552,0,1,0.448,1,1v0C16,15.552,15.552,16,15,16z
                    "/>
            </g>
        </svg>
    )
}
