import React from 'react';

export default function NewOrderIcon() {
    return (
        <svg className="new-order" viewBox="0 0 16 16">
            <g>
                <path d="M13.862,2C13.938,2,14,2.062,14,2.138v11.724C14,13.938,13.938,14,13.862,14H2.138
                    C2.062,14,2,13.938,2,13.862V2.138C2,2.062,2.062,2,2.138,2H13.862 M13.862,0H2.138C0.957,0,0,0.957,0,2.138v11.724
                    C0,15.043,0.957,16,2.138,16h11.724C15.043,16,16,15.043,16,13.862V2.138C16,0.957,15.043,0,13.862,0L13.862,0z"/>
                <path d="M12,8L12,8c0,0.552-0.448,1-1,1H9v2c0,0.552-0.448,1-1,1h0c-0.552,0-1-0.448-1-1V9H5C4.448,9,4,8.552,4,8v0
                    c0-0.552,0.448-1,1-1h2V5c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v2h2C11.552,7,12,7.448,12,8z"/>
            </g>
        </svg>
    )
}
