import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { orderListURL } from '../store/config';
import http from 'services/http';

/**
 * @component
 * @category Dashboard
 * @hideconstructor
 */
function OrderinfoRedirect({ siteCategory }) {
    // This component is just for redirect form /orderinfo?id=E-12354687 to /orderinfo/123456/details

    const history = useHistory();
    const urlParams = new URLSearchParams(history.location.search);
    const id = urlParams.get('id');

    useEffect(() => {
        if (siteCategory) {

            if (id) {
                http
                    .get(orderListURL(siteCategory, {
                        limit: 1,
                        page: 1,
                        q: id
                    }))
                    .then(data => {
                        if (data.orders[0]) history.push(`/orderinfo/${data.orders[0].id}/details`);
                    })
                    .catch(err => {
                        console.log(err);
                        history.push('/dashboard');
                    })



            } else {
                history.push('/dashboard');
            }
        }
    })

    return null;
}

OrderinfoRedirect.propTypes = {
    siteCategory: PropTypes.number
}

const mapStateToProps = ({ global }) => ({
    siteCategory: global.siteCategory
});

export default connect(mapStateToProps)(OrderinfoRedirect);
