import { FETCH_DEFAULT_SETTINGS, SET_PHONE, SELECT_COUNTRY_DATA } from 'store/orderform/orderformActionTypes';
import reducerLogic from 'store/orderform/textfield/textFieldReducer';
import { getDialCode } from 'orderform/utils/orderformUtils';

export const initialState = {
    id: 'phone',
    type: 'tel',
    value: '',
    dialCode: '1',
    country: 'us',
    mask: '(XXX) XXX-XXXX',
    error: '',
    placeholder: 'Enter your phone',
    label: 'Phone number',
    tooltip: 'Please provide your phone number that we can use to reach you in case any questions arise.',
    isRequired: true,
    isFocused: false
}

export default function phoneReducer(state = initialState, action) {

    if (action.type === SET_PHONE) {
        return {
            ...state,
            value: action.payload
        };

    } else if (action.type === SELECT_COUNTRY_DATA) {
        return {
            ...state,
            dialCode: action.payload.dialCode,
            country: action.payload.country
        };

    } else if (action.type === FETCH_DEFAULT_SETTINGS) {

        return {
            ...state,
            country: action.payload.codeCountry || state.country,
            dialCode: action.payload.codeCountry ? (getDialCode(action.payload.codeCountry) || state.dialCode) : state.dialCode
        };

    } else {
        return reducerLogic(state, action);
    }
}