import {
    FETCH_ORDER_LIST,
    SET_ORDER_LIST_PAGE,
    SET_ORDER_LIST_FILTER,
    SET_FEEDBACK_INFO,
    SET_ORDER_LIST_SEARCH,
    GET_FILES_FOR_DOWNLOAD,
    CLEAR_FILES_FOR_DOWNLOAD,
    HIDE_ORDER,
    TOGGLE_HIDDEN_ORDERS,
    UNHIDE_ORDER,
    SET_DATE_FILTER,
    CLEAR_ALL_FILTERS,
    TOGGLE_FETCHITN_STATUS,
    SET_PENDING_STATUS
} from 'store/actionTypes';

export const initialState = {
    orderList: {},
    limit: 12,
    page: 1,
    hidden: false,
    searchValue: '',
    orderStatus: 0,
    from: '',
    to: '',
    isFilterInteract: false,
    isFetching: true,
    emptyOrderlist: false,
    filterOptions: [{
        "id": 0,
        "name": "All"
    },
    {
        "id": 1,
        "name": "Unpaid"
    },
    {
        "id": 2,
        "name": "Paid"
    },
    {
        "id": 3,
        "name": "In Progress"
    },
    {
        "id": 5,
        "name": "Completed"
    },
    {
        "id": 7,
        "name": "Refunded"
    },
    {
        "id": 8,
        "name": "Canceled"
    },
    {
        "id": 9,
        "name": "On Hold"
    },
    // {
    //     "id": 10,
    //     "name": "Modified"
    // },
    {
        "id": 11,
        "name": "Offered"
    }
    ]
}

export default function ordersReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_ORDER_LIST:
            return {
                ...state,
                orderList: payload,
                emptyOrderlist: (payload.orders && !payload.orders.length) || false
            };

        case SET_ORDER_LIST_PAGE:
            return {
                ...state,
                page: payload
            };

        case SET_ORDER_LIST_FILTER:
            return {
                ...state,
                orderStatus: payload,
                page: 1,
                emptyOrderlist: false,
                isFilterInteract: true
            };

        case SET_ORDER_LIST_SEARCH:
            return {
                ...state,
                searchValue: payload,
                emptyOrderlist: false,
                page: 1
            };

        case SET_FEEDBACK_INFO + '_LIST':
            return {
                ...state,
                orderList: {
                    ...state.orderList,
                    orders: state.orderList.orders.map(item => {
                        if (item.id === payload.id) {
                            return {
                                ...item,
                                feedback: payload
                            }
                        } else {
                            return item
                        }
                    })
                }
            }

        case GET_FILES_FOR_DOWNLOAD:
            return {
                ...state,
                orderList: {
                    ...state.orderList,
                    orders: state.orderList.orders.map(item => {
                        if (item.id === payload.orderId) {
                            return {
                                ...item,
                                fileForDownload: payload.list
                            }
                        } else {
                            return item
                        }
                    })
                }
            };

        case CLEAR_FILES_FOR_DOWNLOAD:
            return {
                ...state,
                orderList: {
                    ...state.orderList,
                    orders: state.orderList.orders.map(item => {
                        if (item.id === payload) {
                            return {
                                ...item,
                                fileForDownload: []
                            }
                        } else {
                            return item
                        }
                    })
                }
            };

        case HIDE_ORDER:
            return {
                ...state,
                orderList: {
                    ...state.orderList,
                    orders: state.orderList.orders.filter(item => item.id !== payload)
                }
            }

        case UNHIDE_ORDER:
            return {
                ...state,
                orderList: {
                    ...state.orderList,
                    orders: state.orderList.orders.filter(item => item.id !== payload)
                }
            }

        case TOGGLE_HIDDEN_ORDERS:
            return {
                ...state,
                hidden: !state.hidden,
                emptyOrderlist: false
            }

        case SET_DATE_FILTER:
            return {
                ...state,
                [payload.label]: payload.value,
                page: 1
            }

        case CLEAR_ALL_FILTERS:
            return {
                ...state,
                hidden: false,
                orderStatus: 0,
                page: 1,
                from: '',
                to: '',
                emptyOrderlist: false,
                isFilterInteract: false
            }

        case TOGGLE_FETCHITN_STATUS:
            return {
                ...state,
                isFetching: payload
            }

        case SET_PENDING_STATUS + '_LIST':
            return {
                ...state,
                orderList: {
                    ...state.orderList,
                    orders: state.orderList.orders.map(item => {
                        if (item.id === payload.orderId) {
                            return {
                                ...item,
                                pending: payload.status
                            }

                        } else {
                            return item;
                        }
                    })
                }
            }

        default:
            return state;
    }
}