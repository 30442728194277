import { useSyncedState } from './useSyncedState';
import { useEffect, useRef, useState } from 'react';
import { useMagicRedirect } from './useMagicRedirect';
import { useSelector } from 'react-redux';

const COUNTDOWN_SECONDS = 0; // change for delay

// Redirect
// export const useUpgradeRedirect = () => {

// const [magicLink, setMagicLink] = useState('')

// const href = window.location.href
// const targetLink = href.replace('/old.', '/app.')
//   // Rewrites
//   .replace(/loyalty/gi, 'orders')

// useEffect(() => {
//   console.log('[debug] Redirect URL:>>', targetLink)
// }, [targetLink])

// useEffect(() => {
//   http.get(`${BASE_URL}/api/en/magic-link-core`).then((res) => {
//     if (!res?.uuid) return console.error('Invalid magic link')
//     console.log('RES>>', res)
//     setMagicLink(`${targetLink}?magicLink=${res.uuid}`)
//   })
// }, [])

// const redirectToNewApp = useCallback(async () => {
//   if (window.location.href === targetLink) return console.log('Redirect skipped. Same URLs')
//   document.location.replace(targetLink)
//   return true
// }, [targetLink])

// return { targetLink, redirectToNewApp }
// }

export const useUpgradePage = () => {
  const isAuthorized = useSelector(({ global }) => global.isAuthorized);
  const [skip, setSkip] = useSyncedState('skipUpgradePage');
  const toggleSkip = (e) => setSkip(e.target.checked);

  // Countdown
  const [redirectCountDown, setRedirectCountDown] = useState(COUNTDOWN_SECONDS);
  const _id = useRef(0); // countdown timer id
  const stopCountdown = () => clearInterval(_id.current);

  // Redirect
  const { magicRedirect } = useMagicRedirect();

  useEffect(() => {
    // Countdown (init counter)
    if (!skip || window.location.search.match(/logout/)) return;

    console.log('[debug] HREF', window.location.href);

    // Countdown is finished
    if (redirectCountDown === 0) {
      if (!isAuthorized) return; // skip (prevents some bugs)
      return magicRedirect().then(stopCountdown);
    }

    _id.current = setInterval(() => {
      if (!redirectCountDown) {
        clearInterval(_id.current);
      }
      setRedirectCountDown(redirectCountDown - 1);
    }, 1000);

    return stopCountdown;
  }, [skip, redirectCountDown, magicRedirect, isAuthorized]);

  return {
    toggleSkip,
    skip, // fixed case with local redirect
  };
};
