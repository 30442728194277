import React from 'react';

export default function SettingsIcon() {
    return (
        <svg viewBox="0 0 16.564 16.564">
            <g>
                <path d="M14.267,3.571l-1.742,0.468l0.468-1.742c0.096-0.358-0.015-0.723-0.259-0.968
                    c-0.12-0.12-0.272-0.211-0.447-0.258L8.42,0.034C7.887-0.109,7.338,0.207,7.195,0.741L6.726,2.484L5.453,1.211
                    c-0.391-0.391-1.024-0.391-1.414,0L1.211,4.039c-0.39,0.39-0.391,1.024,0,1.414l1.273,1.273L0.741,7.195
                    C0.207,7.338-0.109,7.887,0.034,8.42l1.037,3.867c0.047,0.176,0.138,0.328,0.258,0.447c0.245,0.245,0.61,0.355,0.968,0.259
                    l1.742-0.468l-0.468,1.742c-0.096,0.358,0.015,0.723,0.259,0.968c0.12,0.12,0.272,0.211,0.447,0.258l3.867,1.037
                    c0.533,0.143,1.081-0.173,1.225-0.706l0.469-1.743l1.273,1.273c0.39,0.39,1.024,0.39,1.414,0l2.828-2.828
                    c0.391-0.391,0.39-1.024,0-1.414L14.08,9.838l1.743-0.469c0.533-0.143,0.849-0.692,0.706-1.225l-1.037-3.867
                    c-0.047-0.176-0.138-0.328-0.258-0.447C14.989,3.585,14.625,3.475,14.267,3.571z M13.044,5.971l0.776-0.209l0.519,1.935
                    l-0.778,0.209c-0.69,0.185-1.228,0.725-1.413,1.415c-0.185,0.69,0.013,1.426,0.518,1.931l0.566,0.566l-1.414,1.414l-0.566-0.566
                    c-0.505-0.505-1.241-0.702-1.931-0.518c-0.69,0.185-1.229,0.723-1.415,1.413l-0.209,0.778L5.762,13.82l0.209-0.776
                    c0.186-0.691-0.011-1.428-0.517-1.934c-0.506-0.506-1.243-0.703-1.934-0.517l-0.776,0.209L2.225,8.867l0.778-0.209
                    c0.69-0.186,1.228-0.725,1.413-1.415C4.6,6.553,4.403,5.817,3.898,5.312L3.332,4.746l1.414-1.414l0.566,0.566
                    C5.817,4.403,6.553,4.6,7.243,4.416s1.229-0.723,1.415-1.413l0.209-0.778l1.935,0.519L10.593,3.52
                    c-0.186,0.691,0.011,1.428,0.517,1.934C11.616,5.959,12.353,6.156,13.044,5.971z"/>
                <circle cx="8.282" cy="8.282" r="2" />
            </g>
        </svg>
    )
}
