import {
    FETCH_ORDER_INFO,
    FETCH_ORDER_INFO_ERROR,
    CLEAR_ORDER_INFO,
    SET_FEEDBACK_INFO,
    CALCULATE_FILES_COUNT,
    GET_MESSAGES_COUNT,
    GO_TO_DASHBOARD,
    SET_PENDING_STATUS,
    SET_TOTAL_UNREAD_COUNT,
    READ_MESSAGES,
    UPDATE_UNREAD,
    CREATE_REVISION
} from 'store/actionTypes';

export const initialState = {
    order: {},
    error: false,
    links: [{
        id: 'details',
        name: 'Order details',
        icon: 'DetailsIcon',
        url: '/details',
        tooltip: '',
        counter: false,
        disabled: false
    },
    {
        id: 'files',
        name: 'Files',
        icon: 'FilesIcon',
        url: '/files',
        counter: false,
        disabled: false
    },
    // {
    //     id: 'messages',
    //     name: 'Messages',
    //     icon: 'MessagesIcon',
    //     url: '/messages',
    //     tooltip: 'You have unread messages',
    //     counter: false,
    //     disabled: false
    // },
    {
        id: 'ordermessenger',
        name: 'Messages',
        icon: 'MessagesIcon',
        url: '/messages',
        tooltip: 'You have unread messages',
        counter: false,
        disabled: false
    },
    {
        id: 'revisions',
        name: 'Revisions',
        icon: 'RevisionsIcon',
        url: '/revisions',
        tooltip: 'You have one active revision',
        counter: false,
        disabled: true
    }
    ]
}

export default function orderinfoReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_ORDER_INFO:
            return {
                ...state,
                order: payload,
                error: false,
                links: state.links.map(item => {
                    if (payload.orderStatus.id === 5 && item.id === 'revisions') {
                        item.disabled = false;
                        item.counter = payload.activeRevision ? '1' : false;
                        return item;

                    } else {
                        return item;
                    }
                })
            };

        case FETCH_ORDER_INFO_ERROR:
            return {
                ...state,
                error: true
            };

        case GO_TO_DASHBOARD:
            return {
                ...state,
                error: false
            };

        case CALCULATE_FILES_COUNT:
            return {
                ...state,
                links: state.links.map(item => {
                    if (item.id === 'files') {
                        item.counter = payload ? payload : false;
                        return item;

                    } else {
                        return item;
                    }
                })
            };

        // Old messenger
        case GET_MESSAGES_COUNT:
            return {
                ...state,
                links: state.links.map(item => {
                    if (item.id === 'messages') {
                        item.counter = payload ? payload : false;
                        return item;

                    } else {
                        return item;
                    }
                })
            };

        // ordermessenger
        case SET_TOTAL_UNREAD_COUNT:
            return {
                ...state,
                links: state.links.map(item => {
                    if (item.id === 'ordermessenger') {
                        item.counter = payload ? payload : false;
                        return item;

                    } else {
                        return item;
                    }
                })
            };

        case UPDATE_UNREAD:
            return {
                ...state,
                links: state.links.map(item => {
                    if (item.id === 'ordermessenger') {
                        item.counter = (payload.user + payload.writer > 0) ? payload.user + payload.writer : false;
                        return item;

                    } else {
                        return item;
                    }
                })
            };

        case READ_MESSAGES:
            return {
                ...state,
                links: state.links.map(item => {
                    if (item.id === 'ordermessenger') {
                        item.counter = (payload.count > 0)
                            ? payload.count
                            : false;
                        return item;

                    } else {
                        return item;
                    }
                })
            }

        case CLEAR_ORDER_INFO:
            return {
                ...state,
                order: {},
                links: state.links.map(item => {
                    if (item.id === 'revisions') {
                        item.disabled = true;
                        item.counter = false;
                        return item;

                    } else if (item.id === 'files') {
                        item.counter = false;
                        return item;

                    } else if (item.id === 'messages') { // old messenger
                        item.counter = false;
                        return item;

                    } else if (item.id === 'ordermessenger') {
                        item.counter = false;
                        return item;

                    } else {
                        return item;
                    }
                })
            };

        case SET_FEEDBACK_INFO + '_INFO':
            return {
                ...state,
                order: {
                    ...state.order,
                    feedback: payload
                }
            }

        case SET_PENDING_STATUS + '_INFO':
            return {
                ...state,
                order: {
                    ...state.order,
                    pending: payload.status
                }
            }

        case CREATE_REVISION:
            return {
                ...state,
                order: {
                    ...state.order,
                    activeRevision: true
                }
            }

        default:
            return state;
    }
}