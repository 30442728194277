import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import http from '../services/http';
import { BASE_URL } from '../constants';

export const useMagicRedirect = () => {
  const isAuthorized = useSelector(({ global }) => global.isAuthorized);
  const [isProcessing, setProcessing] = useState(false);

  const redirect = useCallback((qs = '') => {
    const targetLocation = window.location.href
      // Replacers
      .replace('/old.', '/app.') // default
      .replace(/loyalty/gi, 'orders') // rewrite loyalty route
      .replace(/dashboard/gi, 'orders'); // rewrite loyalty route
    const url = `${targetLocation}?${qs}`;
    // Skip
    if (window.location.href === targetLocation) {
      return console.log('Redirect skipped. Same URLs');
    }

    console.log('Redirect link: >>', `${targetLocation}${qs}`);
    document.location.replace(url);
  }, []);

  const magicRedirect = useCallback(async () => {
    // For guests
    if (!isAuthorized) {
      return redirect();
    }

    // For authorized users only
    setProcessing(true);

    // get magic link
    return http
      .get(`${BASE_URL}/api/en/magic-link-core`)
      .then((res) => {
        // bad response
        if (!res?.uuid) {
          return console.error('Invalid magic link');
        }
        // good response
        return redirect(`magicLink=${res?.uuid}`);
      })
      .catch(console.error)
      .finally(() => {
        setProcessing(false);
      });
  }, [isAuthorized, redirect]);

  return { isProcessing, magicRedirect };
};
