import {
    ADD_FILE,
    UPLOAD_FILE,
    UPLOAD_FILE_ERROR,
    DELETE_FILE,
    CLEAR_FILES,
    CLICK_ON_FILE_INPUT,
    SET_WORK_TYPE,
    SET_DEFAULT_VALUE,
    GET_CUSTOMERS_FILES_ON_EDIT
} from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

export const initialState = {
    label: 'File(s)',
    tooltip: '',
    validTypes: ['.txt', '.zip', '.doc', '.docx', '.xls', '.xlsx', '.rtf', '.jpeg', '.png', '.gif', '.ppt', '.pptx', '.csv', '.pdf', '.jpg', '.odt', '.rar', '.zipx', '.7z', '.gz', '.gzip', '.tar', '.tar-gz'],
    files: {},
    fileObjects: [],
    deletedFiles: [],
    isRequired: false
}

export default function fileReducer(state = initialState, { type, payload }) {
    const deepFilesCopy = JSON.parse(JSON.stringify(state.files));

    switch (type) {
        case ADD_FILE:
            return {
                ...state,
                files: {
                    ...state.files,
                    [payload.id]: {
                        id: payload.id,
                    }
                }
            };

        case CLICK_ON_FILE_INPUT:
            return {
                ...state,
                files: {
                    ...state.files,
                    [payload.id]: {
                        ...state.files[payload.id],
                        isClicked: true
                    }
                }
            };

        case CLEAR_FILES:
            for (let key in deepFilesCopy) {

                if (!deepFilesCopy[key].fileName) {
                    delete deepFilesCopy[key];
                }
            }

            return {
                ...state,
                files: deepFilesCopy
            };

        case UPLOAD_FILE:
            return {
                ...state,
                files: {
                    ...state.files,
                    [payload.id]: {
                        ...state.files[payload.id],
                        id: payload.id,
                        fileValue: payload.fileValue,
                        fileName: payload.fileName,
                        fileSize: payload.fileSize
                    }
                },
                fileObjects: [
                    ...state.fileObjects,
                    {
                        id: payload.id,
                        fileObj: payload.fileObj,
                    }
                ]
            };

        case UPLOAD_FILE_ERROR:
            delete deepFilesCopy[payload.id];

            return {
                ...state,
                files: deepFilesCopy,
                fileObjects: state.fileObjects.filter(item => item.id !== payload.id)
            };

        case DELETE_FILE:
            delete deepFilesCopy[payload.id];

            return {
                ...state,
                files: deepFilesCopy,
                fileObjects: state.fileObjects.filter(item => item.id !== payload.id),
                deletedFiles: [
                    ...state.deletedFiles,
                    payload.remote ? payload.id : null
                ]
            };

        case GET_CUSTOMERS_FILES_ON_EDIT:
            return {
                ...state,
                files: payload.list,
                fileObjects: [
                    ...state.fileObjects,
                    ...payload.fileObjList
                ]
            }

        case SET_WORK_TYPE:
            if (payload === '2') {
                return {
                    ...state,
                    isRequired: true
                }
            } else {
                return {
                    ...state,
                    isRequired: false
                }
            }

        case SET_DEFAULT_VALUE:
            if (payload.workType === '2') {
                return {
                    ...state,
                    isRequired: true
                }
            } else {
                return {
                    ...state,
                    isRequired: false
                }
            }

        case CLEAR_ORDERFORM:
            return {
                ...state,
                files: {},
                fileObjects: [],
                deletedFiles: []
            };

        default:
            return state;
    }
}