import React from 'react';
import { Spin } from 'antd';
import { bool } from 'prop-types';

import styles from './SpinFullscreen.module.scss'

export const SpinFullscreen = (props) => (
  <div className={styles.SpinFullscreen}>
    <Spin size="large" {...props}/>
  </div>
);

SpinFullscreen.defaultProps = {};
SpinFullscreen.propTypes = {
  spinning: bool
};
