import React from 'react';
import PropTypes from 'prop-types';

import PrivacyFooter from './PrivacyFooter';
import Accordion from '../../components/accordion/Accordion';
import ArrowDown from '../../components/img/ArrowDown';

/**
 * @name Settings
 * @component
 * @category Dashboard
 * @subcategory Profile
 * @hideconstructor
 * @memberof PrivacyModal
 */
export default function Settings({ setTab }) {
    return (
        <div className="privacy__settings-wrapper">
            <h2 className="privacy__heading">Privacy settings</h2>
            <div className="privacy__btn-wrapper">
                <button
                    className="privacy__tab-btn"
                    id="personal-btn"
                    onClick={setTab.bind(null, 'PersonalInfo')}
                >
                    <span>Manage my</span><span>personal data</span>
                </button>
                <button
                    className="privacy__tab-btn"
                    id="preferences-btn"
                    onClick={setTab.bind(null, 'Preferences')}
                >
                    <span>Manage my</span><span>consent preferences</span>
                </button>
            </div>

            <Accordion className="settings-acc">
                <Accordion.Item expanded={true}>
                    <Accordion.Toggle>
                        <h5 className="settings-acc__title">
                            Who can see my details?
                        </h5>
                        <ArrowDown />
                    </Accordion.Toggle>
                    <Accordion.Collapse>
                        <span>
                            We strive to bring you the best service along with the highest security and privacy standards. Only Web Site employees can access your account and personal details. This information can be used by marketing specialists, support representatives, and technical staff exclusively for business purposes to provide you with the best service and to improve user experience. Third parties have no access to your account and personal details except for the cases described in the Information Sharing section.
                        </span>
                    </Accordion.Collapse>
                </Accordion.Item>

                <Accordion.Item>
                    <Accordion.Toggle>
                        <h5 className="settings-acc__title">
                            Information sharing
                        </h5>
                        <ArrowDown />
                    </Accordion.Toggle>
                    <Accordion.Collapse>
                        <p>
                            Account and personal details may be shared with third-parties. This is done to ensure that the website is operational and that the services are properly provided. We partner with trusted technical, analytical and financial service providers who guarantee the highest data protection standards. Kindly refer to the list of companies we may be sharing some of your data with:
                        </p>
                        <ul className="settings-acc__list">
                            <li>Amazon Web Services, Inc. or its affiliates;</li>
                            <li>Google LLC or its affiliates;</li>
                            <li>Yandex LLC;</li>
                            <li>SendPulse Inc.;</li>
                            <li>PayCore.io Limited.</li>
                            <li>Paymentwall Inc.;</li>
                            <li>Fondy Ltd;</li>
                            <li>Decta Limited;</li>
                            {/* <li>CardPay Ltd;</li>
                            <li>PayPal Inc.</li> */}
                        </ul>
                    </Accordion.Collapse>
                </Accordion.Item>
            </Accordion>

            <PrivacyFooter backBtn={false} />
        </div>
    )
}

Settings.propTypes = {
    setTab: PropTypes.func
}