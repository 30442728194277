import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CheckIcon from '../../components/img/CheckIcon';

import { fetchRevisionInfoById, clearActiveRevision, fetchRevisionCustomerFiles, fetchRevisionWriterFiles } from '../../../store/dashboard/orderinfo/revisions/revisionActions';
import { toggleModal } from '../../../store/global/globalActions';
import { getInfoByRevisionStatus, getFormatedDate } from '../../../helpers/utils';

/**
 * @name -revisions/InfoModal
 * @component
 * @category Dashboard
 * @subcategory Orderinfo
 * @hideconstructor
 * @memberof Revisions
 */
function InfoModal({ revisionId, revisionFiles, title, revisionInfo, fetchRevisionInfoById, clearActiveRevision, toggleModal, fetchRevisionCustomerFiles, fetchRevisionWriterFiles }) {

    useEffect(() => {
        fetchRevisionInfoById(revisionId);
        fetchRevisionCustomerFiles(revisionId);
        fetchRevisionWriterFiles(revisionId);

        return () => {
            clearActiveRevision();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {revisionInfo.id &&
                <div className={`revision-view ${getInfoByRevisionStatus(revisionInfo.revisionStatus.id).cardClass}`}>
                    <div className="modal__header">
                        {`ID: ${revisionInfo.id} - ${revisionInfo.revisionStatus.name}${getInfoByRevisionStatus(revisionInfo.revisionStatus.id).headerEmoji}`}
                    </div>
                    <div className="modal__body">
                        <span className="modal__deadline">Deadline: {getFormatedDate(revisionInfo.deadline)}</span>
                        <h3 className="modal__title">{title}</h3>
                        <span className="modal__label">Problem:</span>
                        <ul className="modal__list check">
                            {revisionInfo.categories.map((category, index) => (
                                <li className="modal__list-item" key={`${index} + ${category.id}`}>
                                    <span className="modal__icon-box">
                                        <CheckIcon />
                                    </span>
                                    <span className="modal__list-text">
                                        {category.name}
                                    </span>
                                </li>
                            ))}
                        </ul>
                        <span className="modal__label">Your additional info and files:</span>
                        <p className="modal__description">{revisionInfo.description}</p>
                        <ul className="modal__files">
                            {revisionFiles.length ?
                                revisionFiles.map((file, index) => (
                                    <li className="modal__file" key={`${index} + ${file.id}`}>
                                        <svg viewBox="0 0 16 16"><path d="M11,0H2.14A2.14,2.14,0,0,0,0,2.14V13.86A2.14,2.14,0,0,0,2.14,16H13.86A2.14,2.14,0,0,0,16,13.86V5Zm3,13.86a.14.14,0,0,1-.14.14H2.14A.14.14,0,0,1,2,13.86V2.14A.14.14,0,0,1,2.14,2H11V5h3Z" /><rect x="4" y="10" width="8" height="2" /><rect x="4" y="6" width="4" height="2" /></svg>
                                        <span className="modal__file-name">{file.name}</span>
                                    </li>
                                )) : null
                            }
                        </ul>
                        <span className="modal__label">Paper needs to be:</span>
                        <div className="modal__level">{revisionInfo.revisionLevel.name}</div>
                    </div>
                    <div className="modal__footer">
                        <span className="modal__created">
                            Created: {getFormatedDate(revisionInfo.dateCreated)}
                        </span>
                        <button
                            className="modal__close"
                            onClick={toggleModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            }
        </>
    )
}

InfoModal.propTypes = {
    revisionId: PropTypes.number,
    title: PropTypes.string,
    fetchRevisionInfoById: PropTypes.func,
    clearActiveRevision: PropTypes.func,
    toggleModal: PropTypes.func,
    fetchRevisionCustomerFiles: PropTypes.func,
    fetchRevisionWriterFiles: PropTypes.func,
}

const mapStateToProps = ({ dashboard }) => ({
    revisionInfo: dashboard.revisions.activeRevision,
    revisionFiles: dashboard.revisions.revisionFiles,
    title: dashboard.orderinfo.order.title
});

const mapDispatchToProps = {
    fetchRevisionInfoById,
    clearActiveRevision,
    toggleModal,
    fetchRevisionCustomerFiles,
    fetchRevisionWriterFiles
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoModal);